// import React from 'react';
// import Navbar from './components/Navbar/Navbar';
// import HomePage from './components/HomePage/HomePage';
// import Footer from './components/Footer/Footer';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import ProductsPage from './components/ProductsPage/ProductsPage';
// import QuotationPage from './components/QuotationPage/QuotationPage';
// import ProductDetails from './components/ProductDetails/ProductDetails';
// import DesignerShowcase from './components/DesignerShowcase/DesignerShowcase';
// import { CartProvider } from './components/CartContext/CartContext';
// import WishList from './components/WishList/WishList';
// import ScrollToTop from './components/ScrollToTop/ScrollToTop';

// const App = () => {
//   return (
//     <CartProvider>

//     <Router>
//     <ScrollToTop /> {/* גלול תמיד לראש הדף */}

//       <Navbar /> 
//       <Routes>

//         <Route path="/" element={<HomePage />} /> 
//         <Route path="/Final" element={<QuotationPage/>} />
//         <Route path="/:categoryName/:productName" element={<ProductDetails />} />
//         <Route path="/:categoryName" element={<ProductsPage />} />

//       </Routes>
//       <DesignerShowcase/>
//       <Footer /> 

//     </Router>
//     </CartProvider>

//   );
// };

// export default App;


// import React, { useState, useEffect } from 'react';
// import Navbar from './components/Navbar/Navbar';
// import HomePage from './components/HomePage/HomePage';
// import Footer from './components/Footer/Footer';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import ProductsPage from './components/ProductsPage/ProductsPage';
// import QuotationPage from './components/QuotationPage/QuotationPage';
// import ProductDetails from './components/ProductDetails/ProductDetails';
// import DesignerShowcase from './components/DesignerShowcase/DesignerShowcase';
// import { CartProvider } from './components/CartContext/CartContext';
// import ScrollToTop from './components/ScrollToTop/ScrollToTop';
// import { Riple } from 'react-loading-indicators';
// import CatalogPage from './components/CatalogPage/CatalogPage';
// import { pdfjs } from 'react-pdf';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.mjs',
//   import.meta.url,
// ).toString();


// const App = () => {
//   const [isLoading, setIsLoading] = useState(true);

//   // useEffect(() => {
//   //   // מאזין לטעינת האתר
//   //   const handleLoad = () => {
//   //     setIsLoading(false); // מסמן שהכל נטען
//   //   };

//   //   if (document.readyState === 'complete') {
//   //     // אם ה-DOM כבר נטען
//   //     handleLoad();
//   //   } else {
//   //     // מאזין לאירוע טעינה
//   //     window.addEventListener('load', handleLoad);
//   //   }

//   //   return () => {
//   //     // מנקה את האירוע למניעת בעיות בזיכרון
//   //     window.removeEventListener('load', handleLoad);
//   //   };
//   // }, []);


//   useEffect(() => {
//     const handleLoad = () => {
//       console.log('All resources are loaded');
//       setIsLoading(false); // האתר מוכן להיטען
//     };
  
//     // מאזין לאירוע window.onload
//     window.addEventListener('load', handleLoad);
  
//     return () => {
//       // מנקה את האירוע למניעת דליפת זיכרון
//       window.removeEventListener('load', handleLoad);
//     };
//   }, []);
  

//   // useEffect(() => {
//   //   const handleLoad = () => {
//   //     setIsLoading(false);
//   //   };
  
//   //   if (document.readyState === 'complete' || document.readyState === 'interactive') {
//   //     handleLoad();
//   //   } else {
//   //     document.addEventListener('DOMContentLoaded', handleLoad);
//   //   }
  
//   //   return () => {
//   //     document.removeEventListener('DOMContentLoaded', handleLoad);
//   //   };
//   // }, []);


//   if (isLoading) {
//     console.log("Animation on !")
//     return (
//       <div style={styles.loaderContainer}>
//         <Riple speedPlus="0" color="black" />
//         <p>HOMA</p>
//       </div>
//     );
//   }

//   return (
//     <CartProvider>
//       <Router>
//         <ScrollToTop />
//         <Navbar />
//         <Routes>
//           <Route path="/" element={<HomePage />} />
//           <Route path="/Final" element={<QuotationPage />} />
//           <Route path="/Catalouges" element={<CatalogPage />} />

//           <Route path="/:categoryName/:productName" element={<ProductDetails />} />
//           <Route path="/:categoryName" element={<ProductsPage />} />

//         </Routes>
//         <DesignerShowcase />
//         <Footer />
//       </Router>
//     </CartProvider>
//   );
// };

// const styles = {
//   loaderContainer: {
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     alignItems: 'center',
//     height: '100vh',
//     backgroundColor: '#f8f9fa', // צבע רקע ניטרלי
//     fontSize: '18px',
//     color: '#333',
//   },
// };

// export default App;



import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar/Navbar';
import HomePage from './components/HomePage/HomePage';
import Footer from './components/Footer/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProductsPage from './components/ProductsPage/ProductsPage';
import QuotationPage from './components/QuotationPage/QuotationPage';
import ProductDetails from './components/ProductDetails/ProductDetails';
import DesignerShowcase from './components/DesignerShowcase/DesignerShowcase';
import { CartProvider } from './components/CartContext/CartContext';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import CatalogPage from './components/CatalogPage/CatalogPage';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleLoad = () => {
      console.log('DOM fully loaded');
      setIsLoading(false);
    };

    if (document.readyState === 'interactive' || document.readyState === 'complete') {
      handleLoad();
    } else {
      document.addEventListener('DOMContentLoaded', handleLoad);
    }

    return () => {
      document.removeEventListener('DOMContentLoaded', handleLoad);
    };
  }, []);

  if (isLoading) {
    console.log("Animation on!");
    return (
      <div style={styles.loaderContainer}>
        <div style={{ textAlign: 'center' }}>
          <p>Loading...</p>
          <p>HOMA</p>
        </div>
      </div>
    );
  }

  return (
    <CartProvider>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/Final" element={<QuotationPage />} />
          <Route path="/Catalouges" element={<CatalogPage />} />
          <Route path="/:categoryName/:productName" element={<ProductDetails />} />
          <Route path="/:categoryName" element={<ProductsPage />} />
        </Routes>
        <DesignerShowcase />
        <Footer />
      </Router>
    </CartProvider>
  );
};

const styles = {
  loaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f8f9fa',
    fontSize: '18px',
    color: '#333',
  },
};

export default App;