import React from 'react';
import styles from './CategorySelection.module.css';
import { useNavigate } from 'react-router-dom';


const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

function CategoryCard({ name, image, text }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/${capitalizeFirstLetter(name)}`);
  };

  return (
    <article className={styles.categoryCard} onClick={handleClick} role="button" tabIndex={0}>
      <div className={styles.imageWrapper}>
        <img src={image} alt={name} className={styles.categoryImage}       draggable="false"
        />
        {/* <div className={styles.categoryName}>{name}</div> */}
        <div className={styles.categoryName}>{text}</div>

      </div>
    </article>
  );
}

export default CategoryCard;
