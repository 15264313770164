import { useState, useEffect } from "react";
import styles from './Footer.module.css';
import FooterDesktop from "./FooterDesktop/FooterDesktop"; 
import FooterMobile from "./FooterMobile/FooterMobile";

export default function Footer() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <header className={styles.header}>
      {isMobile ? <FooterMobile /> : <FooterDesktop />}
    </header>
  );
}