import React from 'react';
import styles from './BackToCategory.module.css';
import { useNavigate, useLocation } from 'react-router-dom';

const BackToCategory = ({ category }) => { // מקבל את הקטגוריה כפרופס
  const navigate = useNavigate();
  const location = useLocation(); // מקבל את ה-URL הנוכחי

  const handleBackClick = () => {
    const currentPath = location.pathname; // הנתיב הנוכחי
    const newPath = currentPath.substring(0, currentPath.lastIndexOf('/')); // חיתוך החלק האחרון
    navigate(newPath); // ניווט לנתיב החדש
  };

  return (
    <div className={styles.backLinkContainer} onClick={handleBackClick}>
      <nav className={styles.backLink}>
        <div className={styles.backIcon}>
          <img 
            loading="lazy" 
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/f703726bbea38875a2dd22a9ae57a268f7651b54430ffe935ca9b9e51299b137?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd" 
            className={styles.backIconImage} 
            alt="Back arrow"
          />
        </div>
        <span className={styles.backText}>חזור למוצרים</span> {/* טקסט דינמי */}
      </nav>
    </div>
  );
};

export default BackToCategory;