import React, { useEffect, useState } from 'react';
import ProductCard from './ProductCard';
import Slider from 'react-slick';
import styles from './ProductList.module.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';


const ProductList = ({ companyName, category }) => {
  console.log(`category = ${category}`)
  console.log(`companyName = ${companyName}`)

  const location = useLocation();

  const category_file_name = location.pathname.split('/')[1]
  console.log("category_file_name", category_file_name);

  const [products, setProducts] = useState(null);
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' });

  useEffect(() => {
    async function fetchProducts() {
      try {
        const response = await fetch(`/categories/${category_file_name}.json`);
        if (!response.ok) throw new Error('Failed to fetch products');
        const data = await response.json();

        const formattedProducts = Object.keys(data)
          .map((productName) => ({
            image: data[productName].image_url,
            imageUrls: data[productName].image_urls,
            title: productName,
            designer: data[productName].company_name,
            description: data[productName].description,
            originalCategory: data[productName].original_category,
            filterCategory: data[productName].filter_category,
            price: 120,
            rating: 'https://cdn.builder.io/api/v1/image/assets/TEMP/cd6f7e9d11c8e31311a1f943dfb7d413dd37236a822e833b6f143d97b05e8b31?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd',
            placement: data[productName].placement
          }))
          .slice(-5); // הגבלת המוצרים ל-5

          // console.log("formattedProducts", formattedProducts)


        setProducts(formattedProducts);
      } catch (error) {
        console.error('Error loading category products:', error);
      }
    }

    fetchProducts();
  }, [category_file_name]);

  if (!products) return <p>Loading...</p>;


  const sliderSettings = {
    dots: false,
    arrows: !isMobile,
    infinite: true,
    speed: 100,
    autoplay: true,
    autoplaySpeed: 3000,

    slidesToShow: 3, // שלושה כרטיסים ברירת מחדל
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0',
    responsive: [
      {
        breakpoint: 1024, // טאבלט
        settings: {
          slidesToShow: 2, // שני כרטיסים בשורה
        },
      },
      {
        breakpoint: 768, // מובייל
        settings: {
          slidesToShow: 2, // כרטיס אחד בשורה
        },
      },
    ],
  };

  function hello(){
    console.log("Hello!")
  }

  return (
    <main className={styles.productGridContainer}>
          <h2 className={styles.sectionTitle}>{companyName}{'-עוד מ'}</h2>
      <Slider {...sliderSettings} className={styles.slider}>
        {products.map((product, index) => (
          <div key={index}>
            <ProductCard {...product} />
          </div>
        ))}
      </Slider>

    <button 
      className={styles.catalogButton}
      tabIndex="0"
      aria-label="לקטלוג המלא"
      onClick={hello}
    >
      <img 
        loading="lazy" 
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/2609a76f4d2b86095bc5c51e608948c332086c9d4e3115f1c6f9682bbd7b60b9?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd" 
        className={styles.catalogIcon}
        alt=""
      />
      <span className={styles.catalogText}>לקטלוג המלא</span>
    </button>



    </main>
  );
};

export default ProductList;
