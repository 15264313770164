import React from 'react';
import styles from './DesignerCardCss.module.css';
import { useNavigate } from 'react-router-dom';


const capitalizeFirstLetter = (string) => {
  // console.log(string, string.charAt(0).toUpperCase() + string.slice(1).toLowerCase())
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const DesignerCard = ({ backgroundImage, overlayImage, logoSrc, name, description }) => {
  const navigate = useNavigate();

  const handleReadMoreClick = () => {
    navigate(`/${capitalizeFirstLetter(name)}`);
  };

  return (
    <article className={styles.designerCard} style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className={styles.cardContentOne}>
        <img src={overlayImage} alt="" className={styles.cardOverlay} />

        <div className={styles.cardContentTwo}>
          <img src={logoSrc} alt={`${name} logo`} className={styles.designerLogo} />
          <h3 className={styles.brandName}>{name}</h3>
          <p className={styles.designerDescription}>{description}</p>
          <div className={styles.readMore} onClick={handleReadMoreClick} role="button" tabIndex={0}>
            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/93cbf4c1bc79ad1e54937792f3f8c9324d0b77ca2285c6d8cbcc8c804988d6c2?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd" alt="" className={styles.readMoreIcon} />
            <span>קרא עוד</span>
          </div>
        </div>

      </div>
    </article>
  );
};

export default DesignerCard;