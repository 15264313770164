import React, { useState } from 'react';
import Slider from 'react-slick';
import DesignerCard from './DesignerCard';
import styles from './DesignerShowcase.module.css';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import HorizontalRuleRoundedIcon from '@mui/icons-material/HorizontalRuleRounded';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useMediaQuery } from 'react-responsive';


const designersData = [
    {
      backgroundImage: "https://cdn.builder.io/api/v1/image/assets/TEMP/00aebf90f32ca6b82a5836589670069b33cc70f3baf3b961728dacf47e99868d?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
      // backgroundImage: "talenti.jpeg",
      overlayImage: "https://cdn.builder.io/api/v1/image/assets/TEMP/b9aa1e1532698b978ba3dedd2f44d0ba1e29efdce8abf5ebba53473d081a833c?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
      logoSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/2fe580d97bb01e536e4eaff3e935e5382b3cd8103e0cb20a2085507a20b5210f?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
      name: "talenti",
      description: "טלנטי מעצבת ומייצרת רהיטי חוץ: כיסאות, שולחנות ואביזרים המתעוררים לחיים בזכות הנטייה הטבעית של החברה למחקר אסתטי ושיתוף הפעולה שלה עם מעצבים בעלי שם בינלאומי."
    },
    {
      backgroundImage: "https://cdn.builder.io/api/v1/image/assets/TEMP/ddaa8088aee4ab4a135fbef31424f7e92a798eac406669f2e5e900b5cfc137e4?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
      // backgroundImage: "artnova.jpeg",
      overlayImage: "https://cdn.builder.io/api/v1/image/assets/TEMP/e09594383ac7a9ed0b15254765e222b0e17d54b3293a54ac9a4733f99144f1eb?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
      logoSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/a1ccd79c993e1c31d45b26613d86ef846d413b56344c26c8646f2f68c5fec512?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
      name: "artnova",
      description: "ארטנובה מעצבת ומייצרת רהיטי חוץ: כיסאות, שולחנות ואביזרים המתעוררים לחיים בזכות הנטייה הטבעית של החברה למחקר אסתטי ושיתוף הפעולה שלה עם מעצבים בעלי שם בינלאומי."
    },
    {
      backgroundImage: "https://cdn.builder.io/api/v1/image/assets/TEMP/18a2891aead4ec4024af5d8b3ad1f105591963b028e702191331b247dbfedf00?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
      overlayImage: "https://cdn.builder.io/api/v1/image/assets/TEMP/becdb2ad5bbcfc0a3a3fa05ac70c0d2b3990d90b07819a496d2bb0bc2991d1b4?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
      logoSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/504546bbacc8af15011d1579d7d85744024ec6d0e6cf728b0c9e73b47160fb1d?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
      name: "domkapa",
      description: "דומקאפה מעצבת ומייצרת רהיטי חוץ: כיסאות, שולחנות ואביזרים המתעוררים לחיים בזכות הנטייה הטבעית של החברה למחקר אסתטי ושיתוף הפעולה שלה עם מעצבים בעלי שם בינלאומי."
    },
    {
      // backgroundImage: "https://cdn.builder.io/api/v1/image/assets/TEMP/18a2891aead4ec4024af5d8b3ad1f105591963b028e702191331b247dbfedf00?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
      backgroundImage:`${process.env.PUBLIC_URL}/carpanese.jpeg`,
      overlayImage: "https://cdn.builder.io/api/v1/image/assets/TEMP/becdb2ad5bbcfc0a3a3fa05ac70c0d2b3990d90b07819a496d2bb0bc2991d1b4?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
      logoSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/504546bbacc8af15011d1579d7d85744024ec6d0e6cf728b0c9e73b47160fb1d?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
      name: "Carpanese",
      description: "דומקאפה מעצבת ומייצרת רהיטי חוץ: כיסאות, שולחנות ואביזרים המתעוררים לחיים בזכות הנטייה הטבעית של החברה למחקר אסתטי ושיתוף הפעולה שלה עם מעצבים בעלי שם בינלאומי."
    },
    {
        // backgroundImage: "https://cdn.builder.io/api/v1/image/assets/TEMP/18a2891aead4ec4024af5d8b3ad1f105591963b028e702191331b247dbfedf00?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
        backgroundImage:`${process.env.PUBLIC_URL}/paolocastelli.jpeg`,
        overlayImage: "https://cdn.builder.io/api/v1/image/assets/TEMP/becdb2ad5bbcfc0a3a3fa05ac70c0d2b3990d90b07819a496d2bb0bc2991d1b4?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
        logoSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/504546bbacc8af15011d1579d7d85744024ec6d0e6cf728b0c9e73b47160fb1d?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
        name: "PaoloCasteli",
        description: "דומקאפה מעצבת ומייצרת רהיטי חוץ: כיסאות, שולחנות ואביזרים המתעוררים לחיים בזכות הנטייה הטבעית של החברה למחקר אסתטי ושיתוף הפעולה שלה עם מעצבים בעלי שם בינלאומי."
    },
    {
      // backgroundImage: "https://cdn.builder.io/api/v1/image/assets/TEMP/18a2891aead4ec4024af5d8b3ad1f105591963b028e702191331b247dbfedf00?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
      backgroundImage:`${process.env.PUBLIC_URL}/naturedesign.jpeg`,
      overlayImage: "https://cdn.builder.io/api/v1/image/assets/TEMP/becdb2ad5bbcfc0a3a3fa05ac70c0d2b3990d90b07819a496d2bb0bc2991d1b4?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
      logoSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/504546bbacc8af15011d1579d7d85744024ec6d0e6cf728b0c9e73b47160fb1d?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
      name: "naturedesign",
      description: "דומקאפה מעצבת ומייצרת רהיטי חוץ: כיסאות, שולחנות ואביזרים המתעוררים לחיים בזכות הנטייה הטבעית של החברה למחקר אסתטי ושיתוף הפעולה שלה עם מעצבים בעלי שם בינלאומי."
  },
  {
    backgroundImage: "https://cdn.builder.io/api/v1/image/assets/TEMP/18a2891aead4ec4024af5d8b3ad1f105591963b028e702191331b247dbfedf00?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
    backgroundImage:`${process.env.PUBLIC_URL}/francoferri.jpeg`,
    overlayImage: "https://cdn.builder.io/api/v1/image/assets/TEMP/becdb2ad5bbcfc0a3a3fa05ac70c0d2b3990d90b07819a496d2bb0bc2991d1b4?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
    logoSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/504546bbacc8af15011d1579d7d85744024ec6d0e6cf728b0c9e73b47160fb1d?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
    name: "Franco_ferri",
    description: "דומקאפה מעצבת ומייצרת רהיטי חוץ: כיסאות, שולחנות ואביזרים המתעוררים לחיים בזכות הנטייה הטבעית של החברה למחקר אסתטי ושיתוף הפעולה שלה עם מעצבים בעלי שם בינלאומי."
},
{
  backgroundImage: "https://cdn.builder.io/api/v1/image/assets/TEMP/18a2891aead4ec4024af5d8b3ad1f105591963b028e702191331b247dbfedf00?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
  backgroundImage:`${process.env.PUBLIC_URL}/francoferri.jpeg`,
  overlayImage: "https://cdn.builder.io/api/v1/image/assets/TEMP/becdb2ad5bbcfc0a3a3fa05ac70c0d2b3990d90b07819a496d2bb0bc2991d1b4?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
  logoSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/504546bbacc8af15011d1579d7d85744024ec6d0e6cf728b0c9e73b47160fb1d?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
  name: "laskasas",
  description: "דומקאפה מעצבת ומייצרת רהיטי חוץ: כיסאות, שולחנות ואביזרים המתעוררים לחיים בזכות הנטייה הטבעית של החברה למחקר אסתטי ושיתוף הפעולה שלה עם מעצבים בעלי שם בינלאומי."
}
];


  //               src={`${process.env.PUBLIC_URL}/hero_images/${slideImage.url}`} 


  const PrevArrow = ({ onClick }) => (
    <button className={`${styles.arrow} ${styles.prevArrow}`} onClick={onClick}>
      <KeyboardArrowLeft style={{ fontSize: 32, color: '#333' }} />
    </button>
);

const NextArrow = ({ onClick }) => (
    <button className={`${styles.arrow} ${styles.nextArrow}`} onClick={onClick}>
      <KeyboardArrowRight style={{ fontSize: 32, color: '#333' }} />
    </button>
);

const DesignerShowcase = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' });

  const settings = {
    dots: isMobile,
    arrows: !isMobile,
    infinite: true,
    speed: 100,
    fade: isMobile, // שימוש ב-fade רק במובייל
    slidesToShow: isMobile ? 1 : 3, // שקופית אחת במובייל, שלוש בשולחן עבודה
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true, // מוודא שהשקופיות ממוקמות במרכז
    centerPadding: "0px", // מבטל ריווח מסביב
    responsive: [
      {
        breakpoint: 1200, // טאבלט (אייפד)
        settings: {
          slidesToShow: 2, // שתי שקופיות
        },
      },
      {
        breakpoint: 768, // מסכים קטנים יותר
        settings: {
          slidesToShow: 1, // שקופית אחת
        },
      },
    ],
    afterChange: (current) => setCurrentSlide(current),
    customPaging: i => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "30px",
        }}
      >
        <HorizontalRuleRoundedIcon 
          style={{
            fontSize: "30px",
            color: i === currentSlide ? "black" : "grey",
          }} 
        />
      </div>
    ),
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <section className={styles.showcase}>
      <h2 className={styles.subtitle}>המעצבים שלנו</h2>
      <h3 className={styles.title}>גלה נבחרת המעצבים שלנו</h3>

      <div className={styles.carouselContainer}>
        <Slider {...settings}>
          {designersData.map((designer, index) => (
            <div
              key={index}
              className={index === currentSlide ? `${styles.fadeSlide} active-slide` : `${styles.fadeSlide}`}
            >
              <DesignerCard {...designer} />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default DesignerShowcase;