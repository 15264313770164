// import React, { useState } from "react";
// import ToggleButton from "@mui/material/ToggleButton";
// import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

// const SegmentedControl = () => {
//   const [selected, setSelected] = useState("All");

//   const handleChange = (event, newSelection) => {
//     if (newSelection !== null) {
//       setSelected(newSelection);
//     }
//   };

//   return (
//     <div
//       style={{
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         height: "10vh", // גובה של 10% מהמסך
//         backgroundColor: "#e6e4de",
//         width: "100vw",
//       }}
//     >
//       <ToggleButtonGroup
//         value={selected}
//         exclusive
//         onChange={handleChange}
//         aria-label="segmented control"
//         style={{
//           borderRadius: "10px", // פינות מעוגלות
//           width: "50%", // רוחב של 50% מהמסך
//           border: "1px solid black", // גבול שחור סביב כל הקבוצה
//           overflow: "hidden", // חיתוך גבולות
//           marginTop: '10vh'
//         }}
//       >
//         <ToggleButton
//           value="Indoor"
//           style={{
//             textTransform: "none",
//             backgroundColor: selected === "Indoor" ? "black" : "#e6e4de",
//             color: selected === "Indoor" ? "white" : "black",
//             flex: 1, // חלוקה שווה
//             border: "none", // אין גבולות נוספים
//           }}
//         >
//           Indoor
//         </ToggleButton>
//         <ToggleButton
//           value="All"
//           style={{
//             textTransform: "none",
//             backgroundColor: selected === "All" ? "black" : "#e6e4de",
//             color: selected === "All" ? "white" : "black",
//             flex: 1, // חלוקה שווה
//             borderLeft: "1px solid black", // גבול שמאלי
//           }}
//         >
//           All
//         </ToggleButton>
//         <ToggleButton
//           value="Outdoor"
//           style={{
//             textTransform: "none",
//             backgroundColor: selected === "Outdoor" ? "black" : "#e6e4de",
//             color: selected === "Outdoor" ? "white" : "black",
//             flex: 1, // חלוקה שווה
//             borderLeft: "1px solid black", // גבול שמאלי

//           }}
//         >
//           Outdoor
//         </ToggleButton>
//       </ToggleButtonGroup>
//     </div>
//   );
// };

// export default SegmentedControl;


import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const SegmentedControl = ({ selected, setSelected }) => {
  const handleChange = (event, newSelection) => {
    if (newSelection !== null) {
      setSelected(newSelection); // עדכון הבחירה
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "10vh", // גובה של 10% מהמסך
        backgroundColor: "#e6e4de",
        width: "100vw",
      }}
    >
      <ToggleButtonGroup
        value={selected}
        exclusive
        onChange={handleChange}
        aria-label="segmented control"
        style={{
          borderRadius: "10px", // פינות מעוגלות
          width: "50%", // רוחב של 50% מהמסך
          border: "1px solid black", // גבול שחור סביב כל הקבוצה
          overflow: "hidden", // חיתוך גבולות
          marginTop: "10vh",
        }}
      >
        <ToggleButton
          value="Indoor"
          style={{
            textTransform: "none",
            backgroundColor: selected === "Indoor" ? "black" : "#e6e4de",
            color: selected === "Indoor" ? "white" : "black",
            flex: 1, // חלוקה שווה
            border: "none", // אין גבולות נוספים
          }}
        >
          Indoor
        </ToggleButton>
        <ToggleButton
          value="All"
          style={{
            textTransform: "none",
            backgroundColor: selected === "All" ? "black" : "#e6e4de",
            color: selected === "All" ? "white" : "black",
            flex: 1, // חלוקה שווה
            borderLeft: "1px solid black", // גבול שמאלי
          }}
        >
          All
        </ToggleButton>
        <ToggleButton
          value="Outdoor"
          style={{
            textTransform: "none",
            backgroundColor: selected === "Outdoor" ? "black" : "#e6e4de",
            color: selected === "Outdoor" ? "white" : "black",
            flex: 1, // חלוקה שווה
            borderLeft: "1px solid black", // גבול שמאלי
          }}
        >
          Outdoor
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

export default SegmentedControl;