import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const slideImages = [
  {url: 'first.jpg'},
  {url: 'Sofas.png'},
  {url: 'second.jpg'},
  { url: 'third.jpg'},
];

const HeroSection = () => {
  const properties = {
    duration: 3000,
    transitionDuration: 500,
    autoplay: true,
    infinite: true,
    indicators: false,
    arrows: false // החצים לא יוצגו
  };

  const desktopStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    height: '600px', // גובה למחשב
    width: '100%',
    paddingTop: "0px",
    margin: 0
  };

  const mobileStyle = {
    ...desktopStyle,
    height: '300px', // גובה במובייל
  };

  return (
    <div className="slide-container">
      <Slide {...properties}>
        {slideImages.map((slideImage, index) => (
          <div
            key={index}
            style={window.innerWidth <= 768 ? mobileStyle : desktopStyle}
          >
            <img
              src={`${process.env.PUBLIC_URL}/hero_images/${slideImage.url}`} 
              alt={`Slide ${index + 1}`}
              loading="lazy"
              draggable="false" // מונע גרירת התמונה

              style={{
                width: '100%',
                height: '100%',
                objectFit: window.innerWidth <= 768 ? 'cover' : 'cover',
              }}
            />
          </div>
        ))}
      </Slide>
    </div>
  );
};

export default HeroSection;