// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import ProductCard from './ProductCard';  // 3 in row
// import Pagination from './Pagination';
// import styles from './ProductSelection.module.css';


// const ProductSelection = () => {
//   const { categoryName } = useParams(); 
//   const [products, setProducts] = useState(null);
//   // console.log("Hai!!!!!", categoryName)


//   useEffect(() => {
//     async function fetchProducts() {
//       try {
//         // console.log(`Trying to fetch ${categoryName}.json`)
//         const response = await fetch(`/categories/${categoryName}.json`);
//         if (!response.ok) throw new Error("Failed to fetch products(HAI)");
//         const data = await response.json();

//         const formattedProducts = Object.keys(data).map((productName) => ({
//           image: data[productName].image_url,
//           imageUrls: data[productName].image_urls,
//           title: productName ,
//           designer: data[productName].company_name,
//           description: data[productName].description,
//           originalCategory: data[productName].original_category,
//           filterCategory: data[productName].filter_category,
//           price: 120,
//           rating: "https://cdn.builder.io/api/v1/image/assets/TEMP/cd6f7e9d11c8e31311a1f943dfb7d413dd37236a822e833b6f143d97b05e8b31?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
//           placement: data[productName].placement
//         }));

//         setProducts(formattedProducts);
//       } catch (error) {
//         console.error("Error loading category products:", error);
//       }
//     }

//     fetchProducts();
//   }, [categoryName]);

//   if (!products) return <p>Loading...</p>;

//   return (
//     <main className={styles.productGridContainer}>
//       <section className={styles.productGrid}>
//         {products.map((product, index) => (
//           <ProductCard key={index} {...product} />
//         ))}
//       </section>
//       {/* <Pagination /> */}
//     </main>
//   );
// };

// export default ProductSelection;



import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProductCard from "./ProductCard"; // 3 in row
import SegmentedControl from "../../SegmentedControl/SegmentedControl";
import styles from "./ProductSelection.module.css";

const ProductSelection = () => {
  const { categoryName } = useParams();
  const [products, setProducts] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [selectedPlacement, setSelectedPlacement] = useState("All");

  useEffect(() => {
    async function fetchProducts() {
      try {
        const response = await fetch(`/categories/${categoryName}.json`);
        if (!response.ok) throw new Error("Failed to fetch products");
        const data = await response.json();

        const formattedProducts = Object.keys(data).map((productName) => ({
          image: data[productName].image_url,
          imageUrls: data[productName].image_urls,
          title: productName,
          designer: data[productName].company_name,
          description: data[productName].description,
          originalCategory: data[productName].original_category,
          filterCategory: data[productName].filter_category,
          price: 120,
          rating:
            "https://cdn.builder.io/api/v1/image/assets/TEMP/cd6f7e9d11c8e31311a1f943dfb7d413dd37236a822e833b6f143d97b05e8b31?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
          placement: data[productName].placement,
        }));

        setProducts(formattedProducts);
        setFilteredProducts(formattedProducts); // הצגה ראשונית של כל המוצרים
      } catch (error) {
        console.error("Error loading category products:", error);
      }
    }

    fetchProducts();
  }, [categoryName]);

  useEffect(() => {
    if (products) {
      const filtered = products.filter((product) => {
        if (selectedPlacement === "All") return true;
        if (product.placement === "-") return true;
        return product.placement.includes(selectedPlacement);
      });
      setFilteredProducts(filtered);
    }
  }, [selectedPlacement, products]);

  if (!filteredProducts) return <p>Loading...</p>;

  return (
    <main className={styles.productGridContainer}>
      {/* שילוב הכפתור */}
      <SegmentedControl
        selected={selectedPlacement}
        setSelected={setSelectedPlacement}
      />
      <section className={styles.productGrid}>
        {filteredProducts.map((product, index) => (
          <ProductCard key={index} {...product} />
        ))}
      </section>
    </main>
  );
};

export default ProductSelection;