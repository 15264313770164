import { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import styles from './CatalogPage.module.css';

const catalogs = {
  Artnova: [
    "ArtnovaCatalog.pdf",
    '1543486326__LeatherTechnicalInfo_2018 copia.pdf',
    'ART NOVA - NEW FABRIC COLLECTION 2023 (PHOTO VOLUME).pdf',
    'ART NOVA-Catalog (1).pdf',
    'CATALOGO ARTNOVA 2023 Lower.pdf',
    'TURATI T4 SLASH Preview 2024 - depliant.pdf',
  ],
};

function CatalogPage() {
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedCatalog, setSelectedCatalog] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageWidth, setPageWidth] = useState(window.innerWidth * 0.9);
  const [minHeight, setMinHeight] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth * 0.9);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setCurrentPage(1);
  }

  function onPageLoadSuccess({ height }) {
    if (!minHeight) {
      setMinHeight(height); // הגדרת גובה מינימלי לפי העמוד הראשון
    }
  }

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < numPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>קטלוגים</h1>

      {/* Dropdown לבחירת חברה */}
      <div className={styles.dropdownContainer}>
        <div className={styles.selectContainer}>
          <select
            id="companySelect"
            value={selectedCompany}
            onChange={(e) => {
              setSelectedCompany(e.target.value);
              setSelectedCatalog(null);
              setMinHeight(null);
            }}
          >
            <option value="">בחר חברה</option>
            {Object.keys(catalogs).map((company) => (
              <option key={company} value={company}>
                {company}
              </option>
            ))}
          </select>
          <ArrowDropDownIcon className={styles.icon} />
        </div>
      </div>

      {/* Dropdown לבחירת קטלוג */}
      {selectedCompany && (
        <div className={styles.dropdownContainer}>
          <div className={styles.selectContainer}>
            <select
              id="catalogSelect"
              value={selectedCatalog}
              onChange={(e) => setSelectedCatalog(e.target.value)}
            >
              <option value="">בחר קטלוג</option>
              {catalogs[selectedCompany].map((catalog) => (
                <option key={catalog} value={catalog}>
                  {catalog}
                </option>
              ))}
            </select>
            <ArrowDropDownIcon className={styles.icon} />
          </div>
        </div>
      )}

      {/* תצוגת הקטלוג */}
      {selectedCatalog && (
        <div>
          <div
            className={styles.pdf}
            style={{ minHeight: minHeight ? `${minHeight}px` : '500px' }}
          >
            <Document
              file={`${process.env.PUBLIC_URL}/all_catalouges/${selectedCompany}/${selectedCatalog}`}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page
                pageNumber={currentPage}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                width={pageWidth}
                onLoadSuccess={onPageLoadSuccess}
              />
            </Document>
          </div>

          {/* ניווט */}
          <div className={styles.navigation}>
            <button
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
              className={styles.navButton}
            >
              חזור
            </button>
            <span className={styles.pageInfo}>
              עמוד {currentPage} מתוך {numPages}
            </span>
            <button
              onClick={handleNextPage}
              disabled={currentPage === numPages}
              className={styles.navButton}
            >
              הבא
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CatalogPage;

