import React from "react";
import styles from './FooterMobile.module.css';

function ContentItem({ icon, text }) {
  return (
    <div className={styles.contentItem}>
      <img loading="lazy" src={icon} alt="" className={styles.icon} />
      <div className={styles.text}>{text}</div>
    </div>
  );
}

export default ContentItem;