// import React, { useState, useEffect, useRef } from 'react';
// import styles from './NavbarDesktop.module.css';
// import { useNavigate } from 'react-router-dom';
// import SearchIcon from '@mui/icons-material/Search';
// import Autocomplete from '@mui/material/Autocomplete';
// import TextField from '@mui/material/TextField';
// import { options } from '../Options';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

// function NavbarDesktop() {
//   const navigate = useNavigate();
//   const [showPopup, setShowPopup] = useState(false);
//   const [searchValue, setSearchValue] = useState('');
//   const popupRef = useRef(null);

//   const filteredOptions = Object.keys(options)
//     .filter((key) =>
//       key.toLowerCase().startsWith(searchValue.toLowerCase())
//     )
//     .slice(0, 5)
//     .map((key) => ({ name: key, ...options[key] }));

//   if (filteredOptions.length < 5) {
//     const additionalOptions = Object.keys(options)
//       .filter(
//         (key) =>
//           key.toLowerCase().includes(searchValue.toLowerCase()) &&
//           !filteredOptions.some((option) => option.name === key) // הסרת כפילויות
//       )
//       .slice(0, 5 - filteredOptions.length)
//       .map((key) => ({ name: key, ...options[key] }));

//     filteredOptions.push(...additionalOptions);
//   }

//   useEffect(() => {
//     // פונקציה לסגירת הפופ-אפ אם לוחצים מחוץ
//     const handleClickOutside = (event) => {
//       if (popupRef.current && !popupRef.current.contains(event.target)) {
//         setShowPopup(false);
//       }
//     };

//     // מאזין קליק לכל הדף
//     document.addEventListener('mousedown', handleClickOutside);
//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, []);

//   const handleProductClick = (option) => {

//     const productUrl = `/${option.company_name}/${option.name.toLowerCase().replace(/ /g, '-')}`;
//     // .replace('×', 'x') // ממיר את התו המיוחד ל-'x'
//     // .replace('%C3%97', '×'); // מטפל בקידוד URL אם יש אותו
//     setShowPopup(false); 
//     navigate(productUrl);
//   };

//   return (
//     <header className={styles.header}>
//       <nav className={styles.mainNav}>

//         <div className={styles.socialIcons}>
//           <ShoppingCartOutlinedIcon className={styles.socialIcon} onClick={() => navigate('/Final')}/>
//           <WhatsAppIcon className={styles.socialIcon} onClick={() => window.open('https://wa.me/message/RLCICLSB2DOOF1', '_blank')}></WhatsAppIcon>
//         </div>

//         <div className={styles.navLinks}>
//           <a className={styles.navLink}onClick={() => window.open('https://wa.me/message/RLCICLSB2DOOF1', '_blank')}>צור קשר</a>

//           <a className={styles.navLink}>
//             <img 
//               src="https://cdn.builder.io/api/v1/image/assets/TEMP/1a799486ca1cddda426a8c6475944ddb4dae8729f9127472e4c96dcf81bbf6bd?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd" 
//               alt="" className={styles.storeIcon} 
//             />
//             חנות
//           </a>

//           <a className={styles.navLink}
//           >אודות
//           </a>

//           <a className={styles.navLink}
//             onClick={() => setShowPopup(true)}
//             style={{ cursor: 'pointer' }}
//           >
//             <SearchIcon className={styles.searchIcon} />
//             חיפוש
//           </a>

//           <a className={styles.navLink} 
//             onClick={() => navigate('/')} 
//             style={{ cursor: 'pointer' }}
//           >
//             בית
//           </a>

//         </div>
        
//         <img 
//           src='/homa_nav.png'
//           alt="Logo" 
//           className={styles.logo} 
//         />
        
//       </nav>
      
//       {showPopup && (
//         <div className={styles.popup} ref={popupRef}>
//           <div className={styles.searchContainer}>
//             <Autocomplete
//               options={filteredOptions} // שימוש באפשרויות המסוננות
//               value={searchValue}
//               onInputChange={(event, newValue) => setSearchValue(newValue)}
//               renderInput={(params) => (
//                 <TextField {...params} label="חיפוש" variant="outlined" fullWidth />
//               )}
//               disablePortal // מבטל את ה-dropdown המובנה
//               PaperComponent={() => null} // מבטל את רכיב ה-Paper
//               className={styles.autocomplete}
//             />
//           </div>
          
//           {searchValue && (
//             <div className={styles.popupBody}>
//               {filteredOptions.map((option, index) => (
//                 <div
//                   key={index}
//                   className={styles.row}
//                   onClick={() => handleProductClick(option)} // ניווט לדף המוצר
//                   style={{ cursor: 'pointer' }} // שינוי לסמן לחיץ
//                 >
//                   <img
//                     src={option.image_url}
//                     alt={option.name}
//                     className={styles.productImage}
//                   />
//                   <span className={styles.productName}>{option.name}</span>
//                 </div>
//               ))}
//             </div>
//           )}
//         </div>
//       )}
//     </header>
//   );
// }

// export default NavbarDesktop;


import React, { useState } from 'react';
import styles from './NavbarDesktop.module.css';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import SearchPopup from '../../SearchPopup/SearchPopup';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { options } from '../Options';
import Panel from '../../Panel/Panel';

function NavbarDesktop() {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
    setShowPopup(false); // לסגור את החיפוש אם פתוח
  };

  const toggleSearchPopup = () => {
    setShowPopup(!showPopup);
    setIsPanelOpen(false); // לסגור את החנות אם פתוח
  };

  const handleProductClick = (option) => {
    const productUrl = `/${option.company_name}/${option.name.toLowerCase().replace(/ /g, '-')}`;
    setShowPopup(false);
    navigate(productUrl);
  };

  return (
    <header className={styles.header}>
      <nav className={styles.mainNav}>
        <div className={styles.socialIcons}>
          <ShoppingCartOutlinedIcon
            className={styles.socialIcon}
            onClick={() => navigate('/Final')}
          />
          <WhatsAppIcon
            className={styles.socialIcon}
            onClick={() => window.open('https://wa.me/message/RLCICLSB2DOOF1', '_blank')}
          />
        </div>

        <div className={styles.navLinks}>
          <a className={styles.navLink}
            onClick={() => window.open('https://wa.me/message/RLCICLSB2DOOF1', '_blank')}>
            צור קשר
          </a>

          <a className={styles.navLink} onClick={togglePanel}>
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/1a799486ca1cddda426a8c6475944ddb4dae8729f9127472e4c96dcf81bbf6bd?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd"
              alt="Store Icon"
              className={styles.storeIcon}
            />
            חנות
          </a>

          <a className={styles.navLink}
            onClick={toggleSearchPopup}
            style={{ cursor: 'pointer' }}
          >
            <SearchIcon className={styles.searchIcon} />
            חיפוש
          </a>

          <a className={styles.navLink}
            onClick={() => navigate('/')}
            style={{ cursor: 'pointer' }}>
            בית
          </a>
        </div>

        <img src="/homa_nav.png" alt="Logo" className={styles.logo} />
      </nav>

      {/* רכיב חנות */}
      <Panel isOpen={isPanelOpen} onClose={() => setIsPanelOpen(false)} />

      {/* רכיב חיפוש */}
      {showPopup && (
        <SearchPopup
          options={options}
          onClose={() => setShowPopup(false)}
          onProductClick={handleProductClick}
        />
      )}
    </header>
  );
}

export default NavbarDesktop;