import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import styles from './ProductDetailsMobile.module.css';
import ProductInfo from './ProductInfo';
import BreadcrumbNav from '../../BreadcrumbNav/BreadcrumbNav';
import ImageComponent from '../../ImageComponent/ImageComponent';
import ProductList from '../../ProductList/ProductList';
import { useLocation } from 'react-router-dom';
import { Riple } from 'react-loading-indicators';

const normalizeKeys = (product) => {
  const keyMapping = {
    company_name: 'designer',
    image_urls: 'imageUrls',
    filter_category: 'filterCategory',
    original_category: 'originalCategory'
  }; 

  return Object.keys(product).reduce((acc, key) => {
    const newKey = keyMapping[key] || key; // אם המפתח לא במיפוי, שומר אותו כמו שהוא
    acc[newKey] = product[key];
    return acc;
  }, {});
};

const normalizeString = (str) => {
  return str.toLowerCase().replace(/-/g, ' '); // ממיר לקטנות ומחליף "-" ברווח
};

const ProductDetailsMobile = () => {
  const location = useLocation();
  const productFromState = location.state;

  const [product, setProduct] = useState(productFromState);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(!productFromState);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const pathParts = location.pathname.split('/');
        const categoryName = pathParts[pathParts.length - 2];
        const productName = normalizeString(pathParts[pathParts.length - 1]).replace('%c3%97', '×'); 

        const jsonFilePath = `/categories/${categoryName}.json`; // טוען JSON לפי הקטגוריה
        const response = await fetch(jsonFilePath);

        if (!response.ok) {
          throw new Error(`JSON file for category "${categoryName}" not found`);
        }

        const data = await response.json();

        // המרת כל ה-keys לאותיות קטנות להשוואה
        const normalizedData = Object.keys(data).reduce((acc, key) => {
          acc[normalizeString(key)] = key; // שמירה של המפתח המקורי
          return acc;
        }, {});

        const originalKey = normalizedData[productName];
        if (!originalKey) {
          throw new Error(`Product "${productName}" not found in category "${categoryName}"`);
        }

        const productData = data[originalKey];
        if (!productData) {
          throw new Error(`Product data not found for "${originalKey}"`);
        }

        // המרת מפתחות למפתחות החדשים והוספת title עם השם המקורי
        const normalizedProduct = normalizeKeys(productData);
        normalizedProduct.title = originalKey; // שמירה של השם המקורי מהקובץ

        setProduct(normalizedProduct);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    if (!productFromState) {
      fetchProductData();
    } else {
      setProduct(productFromState);
      setLoading(false);
    }
  }, [location.pathname, productFromState]); // מאזין לשינויים בנתיב

  if (loading) {
    return (
      // <div style={riple_style}>
      //   <Riple speedPlus="0" color="black" />
      //   <p>HOMA</p>
      // </div>
      <div>טוען נתוני מוצר...</div>
    );
  }

  if (error) {
    return <div>שגיאה: {error}</div>;
  }

  if (!product) {
    return <div>לא נמצאו נתוני מוצר</div>;
  }

  const imageUrls = product.imageUrls;
  while (imageUrls.length < 4) {
    imageUrls.push(imageUrls[0]); // מוסיף תמונות אם יש פחות מ-4
  }

  const settings = {
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current) => setCurrentSlide(current),
    customPaging: (i) => {
      const isManyImages = imageUrls.length > 10;
      return (
        <div
          style={{
            width: isManyImages ? '15px' : '20px',
            height: '2px',
            backgroundColor: i === currentSlide ? 'black' : '#d3d3d3',
            margin: '0 2px',
            transition: 'background-color 0.3s ease',
          }}
        ></div>
      );
    },
    appendDots: (dots) => {
      const isManyImages = imageUrls.length > 10;
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: isManyImages ? '2px' : '4px',
            padding: '20px 0',
            flexWrap: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {dots}
        </div>
      );
    },
  };
  
  return (
    <main className={styles.productPageMobile}>
      <Slider {...settings}>
        {imageUrls.map((url, index) => (
          <div className={styles.productShowcaseContainer} key={index}>
            <img
              src={url}
              alt={`Product image ${index + 1}`}
              className={styles.productShowcase}
            />
          </div>
        ))}
      </Slider>

      <BreadcrumbNav />
      <ProductInfo data={product} />

      <ImageComponent
        category={product.originalCategory}
        companyName={product.designer}
      />

      <ProductList
        category={product.originalCategory}
        companyName={product.designer}
      ></ProductList>
    </main>
  );
};


const riple_style = {
  loaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f8f9fa', // צבע רקע ניטרלי
    fontSize: '18px',
    color: '#333',
  },
};

export default ProductDetailsMobile;