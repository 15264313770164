import React from 'react';
import styles from './SocialSharing.module.css';

const SocialSharing = () => {
  const socialIcons = [
    { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/61b36d8af958e16ce0893017c04d77df95008d9a256290b2e184be2456074f1d?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd", alt: "Facebook" },
    { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/484cb75d659af7469ef02d6038ae6028ccdf1d14f53fb4ac564b89c095025817?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd", alt: "Twitter" },
    { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/0d4b2863165c6e776999efd01430f7ecf319913a1b3033fcf5f822d2ed5003aa?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd", alt: "Instagram" }
  ];

  return (
    <section className={styles.socialSharing}>
      <h2 className={styles.shareLabel}>Share:</h2>
      <div className={styles.socialIcons}>
        {socialIcons.map((icon, index) => (
          <a key={index} href="#" className={styles.socialLink}>
            <img src={icon.src} alt={icon.alt} className={styles.socialIcon} />
          </a>
        ))}
      </div>
    </section>
  );
};

export default SocialSharing;