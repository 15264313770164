// import React from 'react';
// import styles from './WishlistButton.module.css';
// import { useCart } from '../CartContext/CartContext';


// const WishlistButton = ({product}) => {
//   const { addToCart } = useCart();
//     // console.log(`WishlistButton : product = ${product.image}`)


//   const handleAddToCart = () => {
//     const item = {
//       name: product.title,
//       image: product.image,
//       price: 120,
//       quantity: 1, // אפשר להגדיר ברירת מחדל
//     };
//     addToCart(item);
//     // console.log(`handleAddToCart item = ${item.image}`)
//   };

//   return (
//     <button onClick={handleAddToCart} className={styles.wishlistButton}>
//       <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/47692e12ce2038346cfeadcfd06c6566bad4525cda44eb18517aff0085b26b3e?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd" alt="" className={styles.wishlistIcon} />
//       <span className={styles.wishlistText}>הוסף לרשימה שלי</span>
//     </button>
//   );
// };

// export default WishlistButton;

import React, { useState } from 'react';
import styles from './WishlistButton.module.css';
import { useCart } from '../CartContext/CartContext';
import { useNavigate } from 'react-router-dom';

const WishlistButton = ({ product }) => {
  const { addToCart } = useCart();
  const [isModalOpen, setIsModalOpen] = useState(false); // מצב לחלון פופ-אפ
  const navigate = useNavigate(); // ניווט לדפים

  const handleAddToCart = () => {
    const item = {
      name: product.title,
      image: product.image,
      price: 120,
      quantity: 1, // אפשר להגדיר ברירת מחדל
    };
    addToCart(item);
    setIsModalOpen(true); // פותח את הפופ-אפ
  };

  const closeModal = () => {
    setIsModalOpen(false); // סוגר את הפופ-אפ
  };

  const goToCart = () => {
    setIsModalOpen(false); // סוגר את הפופ-אפ
    navigate('/Final'); // מעביר לעגלת הקניות
  };

  return (
    <div>
      <button onClick={handleAddToCart} className={styles.wishlistButton}>
        <img
          draggable="false"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/47692e12ce2038346cfeadcfd06c6566bad4525cda44eb18517aff0085b26b3e?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd"
          alt=""
          className={styles.wishlistIcon}
        />
        <span className={styles.wishlistText}>הוסף לרשימה שלי</span>
      </button>

      {/* פופ-אפ חלון */}
      {isModalOpen && (
        <div className={styles.modalOverlay} onClick={closeModal}>
          <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
            <h2>{'!המוצר נוסף בהצלחה'}</h2>
            <div className={styles.modalButtons}>
            <button onClick={goToCart} className={styles.cartButton}>
                לעגלת הקניות
              </button>
              <button onClick={closeModal} className={styles.continueButton}>
                להמשך קנייה
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WishlistButton;