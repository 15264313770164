import { useState, useEffect } from "react";
import styles from './ImageGallery.module.css';
import ImageGalleryDesktop from "./ImageGalleryDesktop/ImageGalleryDesktop";
import ImageGalleryMobile from "./ImageGalleryMobile/ImageGalleryMobile";

export default function ImageGallery({ imageUrls }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      {isMobile ? <ImageGalleryMobile imageUrls={imageUrls} /> : <ImageGalleryDesktop imageUrls={imageUrls} />}
    </div>
  );
}