// import React from 'react';
// import styles from './Offer.module.css';

// const Offer = () => {
//   return (

//     <section className={styles.quoteForm}>
//       <div className={styles.formContent}>
//         <h2 className={styles.formTitle}>קבל הצעת מחיר למייל</h2>
//         <form className={styles.formWrapper}>

//           <div className={styles.formFields}>

//             <div className={styles.inputGroup}>
//               <input
//                 type="text"
//                 id="fullName"
//                 className={styles.inputField}
//                 placeholder="שם מלא"
//               />
//             </div>

//             <div className={styles.inputGroup}>
//               <input
//                 type="tel"
//                 id="phone"
//                 className={styles.inputField}
//                 placeholder="טלפון"
//               />
//             </div>

//             <div className={styles.inputGroup}>
//               <input
//                 type="email"
//                 id="email"
//                 className={styles.inputField}
//                 placeholder="אימייל"
//               />
//             </div>

//           </div>

//           <button type="submit" className={styles.submitButton}>
//             שלח לי הצעת מחיר
//           </button>
          
//         </form>
//       </div>
//     </section>
//   );
// };

// export default Offer;

import React, { useState } from 'react';
import styles from './Offer.module.css';

const Offer = () => {
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage('אנא נסה שנית מאוחר יותר');
  };

  return (
    <section className={styles.quoteForm}>
      <div className={styles.formContent}>
        <h2 className={styles.formTitle}>קבל הצעת מחיר למייל</h2>
        <form className={styles.formWrapper} onSubmit={handleSubmit}>
          <div className={styles.formFields}>
            <div className={styles.inputGroup}>
              <input
                type="text"
                id="fullName"
                className={styles.inputField}
                placeholder="שם מלא"
              />
            </div>
            <div className={styles.inputGroup}>
              <input
                type="tel"
                id="phone"
                className={styles.inputField}
                placeholder="טלפון"
              />
            </div>
            <div className={styles.inputGroup}>
              <input
                type="email"
                id="email"
                className={styles.inputField}
                placeholder="אימייל"
              />
            </div>
          </div>
          <button type="submit" className={styles.submitButton}>
            שלח לי הצעת מחיר
          </button>
          {errorMessage && (
            <p className={styles.errorMessage}>{errorMessage}</p>
          )}
        </form>
      </div>
    </section>
  );
};

export default Offer;