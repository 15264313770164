import { useState, useEffect } from "react";
import styles from './ProductDetails.module.css';
import ProductDetailsDesktop from "./ProductDetailsDesktop/ProductDetailsDesktop";
import ProductDetailsMobile from "./ProductDetailsMobile/ProductDetailsMobile";
import { useLocation } from 'react-router-dom';

export default function ProductDetails() {
  const location = useLocation();
  const data = location.state; // כאן תקבל את הנתונים שהועברו דרך ה-state
  // console.log(`designer : ${data.designer}`)

  // console.log(`imageUrls : ${imageUrls}`)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <header className={styles.header}>
      {isMobile ? <ProductDetailsMobile data={data} /> : <ProductDetailsDesktop />}
    </header>
  );
}