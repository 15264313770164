// import React, { useState } from 'react';
// import styles from './ProductCard.module.css';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { useCart } from '../../CartContext/CartContext';

// const ProductCard = ({ image, title, imageUrls, designer, price, rating, description, originalCategory, filterCategory, placement }) => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const { addToCart } = useCart();
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const routeClassMap = {
//     'Artnova': styles.artnovaImage,
//     'Domkapa': styles.domkapaImage,
//     'Talenti': styles.talentiImage,
//     'Carpanese': styles.carpaneseImage,
//     'Paolo Casteli': styles.paolocasteliImage,
//     'Naturedesign': styles.naturedesignImage
//   };

//   const imageClass = routeClassMap[designer] || styles.defaultImageClass;

//   const handleClick = () => {
//     navigate(`${location.pathname}/${title.toLowerCase().replace(/ /g, '-')}`, {
//       state: {
//         title,
//         imageUrls,
//         designer,
//         description,
//         originalCategory,
//         filterCategory,
//         placement,
//         image,
//       },
//     });
//   };

//   const handleRatingClick = (event) => {
//     event.stopPropagation(); // מונע מהלחיצה להפעיל את handleClick
//     const item = {
//       name: title,
//       image: image,
//       price: price,
//       quantity: 1,
//     };
//     addToCart(item); // הוספה לעגלה
//     setIsModalOpen(true); // פתיחת הפופ-אפ
//   };

//   const closeModal = () => {
//     setIsModalOpen(false); // סגירת הפופ-אפ
//   };

//   const goToCart = () => {
//     setIsModalOpen(false); // סגירת הפופ-אפ
//     navigate('/Final'); // מעבר לעגלת הקניות
//   };

//   return (
//     <>
//       <article className={styles.productCard} onClick={handleClick} role="button" tabIndex={0}>
//         <div className={styles.thumbnail}>
//           <img src={image} alt={`${title} by ${designer}`} className={`${styles.productImage} ${imageClass}`} loading="lazy" />
//         </div>
//         <div className={styles.productInfo}>
//           <h3 className={styles.productTitle}>{title}</h3>
//           <p className={styles.designerName}>{placement}, {designer}</p>
//           <div className={styles.priceRating}>
//             <span className={styles.price}>${price}</span>

//             <button className={`${styles.ratingButton}`} onClick={handleRatingClick}>
//               <img src={rating} alt="Product rating" className={styles.ratingImage} />
//             </button>
//           </div>
//         </div>
//       </article>

//       {isModalOpen && (
//         <div className={styles.modalOverlay} onClick={closeModal}>
//           <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
//             <h2>{`!נוסף לעגלה ${title}`}</h2>
//             <div className={styles.modalButtons}>
//               <button onClick={goToCart} className={styles.cartButton}>
//                 לעגלת הקניות
//               </button>
//               <button onClick={closeModal} className={styles.continueButton}>
//                 להמשך קנייה
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default ProductCard;


import React, { useState } from 'react';
import styles from './ProductCard.module.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCart } from '../../CartContext/CartContext';

const ProductCard = ({ image, title, imageUrls, designer, price, rating, description, originalCategory, filterCategory, placement }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { addToCart } = useCart();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const routeClassMap = {
    'Artnova': styles.artnovaImage,
    'Domkapa': styles.domkapaImage,
    'Talenti': styles.talentiImage,
    'Carpanese': styles.carpaneseImage,
    'Paolo Casteli': styles.paolocasteliImage,
    'Naturedesign': styles.naturedesignImage,
    'Laskasas': styles.laskasasImage
  };

  const imageClass = routeClassMap[designer] || styles.defaultImageClass;

  const handleClick = () => {
    navigate(`${location.pathname}/${title.toLowerCase().replace(/ /g, '-')}`, {
      state: {
        title,
        imageUrls,
        designer,
        description,
        originalCategory,
        filterCategory,
        placement,
        image,
      },
    });
  };

  const handleRatingClick = (event) => {
    event.stopPropagation(); // מונע מהלחיצה להפעיל את handleClick
    const item = {
      name: title,
      image: image,
      price: price,
      quantity: 1,
    };
    addToCart(item); // הוספה לעגלה
    setIsModalOpen(true); // פתיחת הפופ-אפ
  };

  const closeModal = () => {
    setIsModalOpen(false); // סגירת הפופ-אפ
  };

  const goToCart = () => {
    setIsModalOpen(false); // סגירת הפופ-אפ
    navigate('/Final'); // מעבר לעגלת הקניות
  };

  return (
    <>
      <article className={styles.productCard} onClick={handleClick} role="button" tabIndex={0}>
        <div className={styles.thumbnail}>
          <img src={image} alt={`${title} by ${designer}`} className={`${styles.productImage} ${imageClass}`} loading="lazy" draggable="false"/>
        </div>
        <div className={styles.productInfo}>
          <h3 className={styles.productTitle}>{title}</h3>
          <p className={styles.designerName}>{placement}, {designer}</p>
          <div className={styles.priceRating}>
            {/* <span className={styles.price}>-</span> */}
            <span className={styles.price}></span>


            <button className={`${styles.ratingButton}`} onClick={handleRatingClick}>
              <img src={rating} alt="Product rating" className={styles.ratingImage} draggable="false"/>
            </button>
          </div>
        </div>
      </article>

      {isModalOpen && (
        <div className={styles.modalOverlay} onClick={closeModal}>
          <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
            <h2>{`!נוסף לעגלה ${title}`}</h2>
            <div className={styles.modalButtons}>
              <button onClick={goToCart} className={styles.cartButton}>
                לעגלת הקניות
              </button>
              <button onClick={closeModal} className={styles.continueButton}>
                להמשך קנייה
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductCard;