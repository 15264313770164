import React from 'react';
import HeroSection from '../HeroSection/HeroSection';
import HomeaBrand from './HomaBrand/HomaBrand';
import CategorySelection from './CategorySelection/CategorySelection';

function HomePage() {
  return (
    <main>
      <HeroSection/>
      <HomeaBrand></HomeaBrand>
      <CategorySelection></CategorySelection>
    </main>
  );
}

export default HomePage;

// <BlinkBlur color="#32cd32" size="medium" text="" textColor="" />

// import React, { useState, useEffect } from 'react';
// import HeroSection from '../HeroSection/HeroSection';
// import HomeaBrand from './HomaBrand/HomaBrand';
// import CategorySelection from './CategorySelection/CategorySelection';
// import {Riple} from 'react-loading-indicators';

// function HomePage() {
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     // דימוי עיכוב בטעינה - מחכים 5 שניות
//     const timer = setTimeout(() => setIsLoading(false), 5000);
//     return () => clearTimeout(timer); // ניקוי ה-timer כשלא צריך אותו
//   }, []);

//   if (isLoading) {
//     return (
//       <div style={styles.loaderContainer}>
//         <Riple speedPlus='0' color="black" />
//         <p>HOMA</p>
//       </div>
//     );
//   }

//   return (
//     <main>
//       <HeroSection />
//       <HomeaBrand />
//       <CategorySelection />
//     </main>
//   );
// }

// const styles = {
//   loaderContainer: {
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     alignItems: 'center',
//     height: '100vh',
//     backgroundColor: '#f8f9fa', // צבע רקע ניטרלי
//     fontSize: '18px',
//     color: '#333',
//   },
// };

// export default HomePage;