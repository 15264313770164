// import React from 'react';
// import styles from './SocialMediaLinks.module.css';

// const SocialMediaLinks = () => {
//   const socialLinks = [
//     { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/43a36fd3583a57c1e4a8a5aac3df5df049ad5280d76c0d1064b8019b38ab191a?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd", alt: "Facebook" },
//     { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/1720ff20c75c54705c269464dc46f32c2926df5b91733d87f1fc173c6cb57673?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd", alt: "Twitter" },
//     { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/14c43e74c3f7eac266106bebe8f8b5e4f45f67eaab8a46b935c2df409d293f4b?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd", alt: "Instagram" },
//     { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/111b4f067700587e329ec8e4a3fb97bed26c1c3f908c428da860719923df8081?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd", alt: "LinkedIn" }
//   ];

//   // https://www.instagram.com/homa_outdoor_kitchens/
//   // https://www.facebook.com/RoyalGardenf1

//   return (
//     <div className={styles.socialMediaContainer}>
//       <h3 className={styles.title}>צור קשר</h3>
//       <div className={styles.iconContainer}>
//         {socialLinks.map((link, index) => (
//           <img 
//             key={index}
//             src={link.src} 
//             alt={link.alt}
//             className={styles.icon} 
//           />
//         ))}
//       </div>
//     </div>
//   );
// };

// export default SocialMediaLinks;


import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './SocialMediaLinks.module.css';

const SocialMediaLinks = () => {
  const navigate = useNavigate();

  const socialLinks = [
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/43a36fd3583a57c1e4a8a5aac3df5df049ad5280d76c0d1064b8019b38ab191a?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
      alt: "Instagram",
      action: () => window.open('https://www.instagram.com/homa_outdoor_kitchens/', '_blank'),

    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/1720ff20c75c54705c269464dc46f32c2926df5b91733d87f1fc173c6cb57673?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
      alt: "Facebook",
      action: () => window.open('https://www.facebook.com/RoyalGardenf1', '_blank'),
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/14c43e74c3f7eac266106bebe8f8b5e4f45f67eaab8a46b935c2df409d293f4b?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
      alt: "LinkedIn",
      action: () => navigate('/'),
    },

    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/111b4f067700587e329ec8e4a3fb97bed26c1c3f908c428da860719923df8081?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
      alt: "Twitter",
      action: () => navigate('/'),
    },

  ];

  return (
    <div className={styles.socialMediaContainer}>
      <h3 className={styles.title}>צור קשר</h3>
      <div className={styles.iconContainer}>
        {socialLinks.map((link, index) => (
          <div
            key={index}
            onClick={link.action}
            className={styles.iconWrapper}
            role="button"
            tabIndex={0}
            aria-label={link.alt}
            onKeyDown={(e) => e.key === 'Enter' && link.action()}
          >
            <img src={link.src} alt={link.alt} className={styles.icon} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SocialMediaLinks;