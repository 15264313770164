import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import styles from './SearchPopup.module.css';

function SearchPopup({ options, onClose, onProductClick }) {
  const [searchValue, setSearchValue] = useState('');
  const popupRef = useRef(null);

  // סינון האפשרויות לפי מה שהמשתמש מקליד
  const filteredOptions = Object.keys(options)
    .filter((key) => key.toLowerCase().includes(searchValue.toLowerCase()))
    .slice(0, 5)
    .map((key) => ({ name: key, ...options[key] }));

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  return (
    <div className={styles.popup} ref={popupRef}>
      {/* שדה הקלט */}
      <div className={styles.searchContainer}>
        <TextField
          label="חיפוש"
          variant="outlined"
          fullWidth
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </div>

      {/* תוצאות החיפוש */}
      {searchValue && (
        <div className={styles.popupBody}>
          {filteredOptions.map((option, index) => (
            <div
              key={index}
              className={styles.row}
              onClick={() => onProductClick(option)}
              style={{ cursor: 'pointer' }}
            >
              <img src={option.image_url} alt={option.name} className={styles.productImage} />
              <span className={styles.productName}>{option.name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SearchPopup;