// import { useState, useEffect } from "react";
// import styles from './Navbar.module.css';
// import NavbarDesktop from "./NavbarDesktop/NavbarDesktop";
// import NavbarMobile from "./NavbarMobile/NavbarMobile";

// export default function Navbar() {
//   const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
//   const [isScrolled, setIsScrolled] = useState(false);

//   useEffect(() => {
//     const handleResize = () => setIsMobile(window.innerWidth < 768);
//     const handleScroll = () => setIsScrolled(window.scrollY > 0);

//     window.addEventListener("resize", handleResize);
//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   return (
//     <header className={`${styles.header} ${isScrolled ? styles.scrolled : ""}`}>
//       {isMobile ? <NavbarMobile /> : <NavbarDesktop />}
//     </header>
//   );
// }



import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import styles from './Navbar.module.css';
import NavbarDesktop from "./NavbarDesktop/NavbarDesktop";
import NavbarMobile from "./NavbarMobile/NavbarMobile";

export default function Navbar() {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isScrolled, setIsScrolled] = useState(false);

  // בדיקה אם מדובר במסך מובייל
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // מעקב אחרי גלילה
  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 1);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // בדיקה אם ה-URL מתאים לתבנית "/:categoryName/:productName"
  const isProductDetails = /^\/[^/]+\/[^/]+$/.test(location.pathname);

  return (
    <header
      className={`${styles.header} ${isScrolled ? styles.scrolled : ""} ${
        isProductDetails ? styles.blackBackground : ""
      }`}
    >
      {isMobile ? <NavbarMobile /> : <NavbarDesktop />}
    </header>
  );
}