import React from 'react';
import styles from './ImageComponent.module.css';
import { useNavigate } from 'react-router-dom';

const OutdoorCollection = ({category, companyName}) => {

  // console.log(`category = ${category}, companyName = ${companyName}`)
  const navigate = useNavigate(); // מאפשר ניווט לנתיבים

  const handleNavigation = () => {
    // מעביר לנתיב ההתחלתי עם ה-companyName
    navigate(`/${companyName}`);
  };
  return (
    <section className={styles.container}>
      <div className={styles.heroSection}>
        <img 
          draggable="false"
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/a08b404db26f4967c52b29bad9b170061c6e9e9a100c60481c032142ef1398aa?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd" 
          alt="Domkapa Outdoor Collection" 
          className={styles.backgroundImage}
        />
        <div className={styles.contentBox}>
          <h2 className={styles.title}>
          {companyName} <br /> {category}
          </h2>
          <button className={styles.ctaButton} onClick={handleNavigation}>
            discover now
          </button>
        </div>
      </div>
    </section>
  );
};

export default OutdoorCollection;