// import React, { useState, useEffect } from 'react';
// import Slider from 'react-slick';
// import styles from './ImageGalleryDesktop.module.css';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
// import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

// const PrevArrow = ({ onClick }) => (
//   <button className={`${styles.arrow} ${styles.prevArrow}`} onClick={onClick}>
//     <KeyboardArrowLeft style={{ fontSize: 25, color: '#333' }} />
//   </button>
// );

// const NextArrow = ({ onClick }) => (
//   <button className={`${styles.arrow} ${styles.nextArrow}`} onClick={onClick}>
//     <KeyboardArrowRight style={{ fontSize: 25, color: '#333' }} />
//   </button>
// );

// const ImageGalleryDesktop = ({ imageUrls }) => {

//   const thumbnails = [
//     ...imageUrls.map((url, index) => ({
//       src: url,
//       alt: `Product thumbnail ${index + 1}`
//     }))
//   ];


//   while (thumbnails.length < 4) {
//     thumbnails.push({
//       src: imageUrls[0], // תמונה ריקה
//       alt: 'Placeholder image'
//     });
//   }

//   const [currentImage, setCurrentImage] = useState(thumbnails[0].src);
//   const [isVisible, setIsVisible] = useState(true);
//   const [allImagesLoaded, setAllImagesLoaded] = useState(false);

//   const handleThumbnailClick = (src) => {
//     setIsVisible(false);
//     setTimeout(() => {
//       setCurrentImage(src);
//       setIsVisible(true);
//     }, 100);
//   };

//   useEffect(() => {
//     const loadImages = async () => {
//       const imagePromises = thumbnails.map((thumb) => {
//         return new Promise((resolve) => {
//           const img = new Image();
//           img.src = thumb.src;
//           img.onload = resolve;
//           img.onerror = resolve;
//         });
//       });

//       await Promise.all(imagePromises);
      
//       setAllImagesLoaded(true);
//     };

//     loadImages();
//   }, [thumbnails]);

//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 4,
//     slidesToScroll: 1,
//     arrows: true,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     centerMode: true,
//     nextArrow: <NextArrow />,
//     prevArrow: <PrevArrow />,
//     centerPadding: '0px',
//     beforeChange: (oldIndex, newIndex) => {
//       // עדכן את התמונה הראשית עם התמונה הבאה
//       setIsVisible(false);
//       setTimeout(() => {
//         setCurrentImage(thumbnails[newIndex].src);
//         setIsVisible(true);
//       }, 100);
//     },
//   };

//   return (
//     <div className={styles.imageGallery}>
//       <figure className={styles.mainImageWrapper}>
//         <img 
//           src={currentImage} 
//           alt="Main product image" 
//           className={`${styles.mainImage} ${isVisible ? 'visible' : ''}`} 
//           loading="lazy"
//         />
//       </figure>
//       {allImagesLoaded ? (
//         <div className={styles.thumbnailList}>
//           <Slider {...settings} className={styles.thumbnailTrack}>
//             {thumbnails.map((thumb, index) => (
//               <img 
//                 key={index} 
//                 src={thumb.src} 
//                 alt={thumb.alt} 
//                 loading="lazy"
//                 className={styles.thumbnail} 
//                 onClick={() => handleThumbnailClick(thumb.src)} 
//               />
//             ))}
//           </Slider>
//         </div>
//       ) : (
//         <div>Loading...</div>
//       )}
//     </div>
//   );
// };

// export default ImageGalleryDesktop;

import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import styles from './ImageGalleryDesktop.module.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const PrevArrow = ({ onClick }) => (
  <button className={`${styles.arrow} ${styles.prevArrow}`} onClick={onClick}>
    <KeyboardArrowLeft style={{ fontSize: 25, color: '#333' }} />
  </button>
);

const NextArrow = ({ onClick }) => (
  <button className={`${styles.arrow} ${styles.nextArrow}`} onClick={onClick}>
    <KeyboardArrowRight style={{ fontSize: 25, color: '#333' }} />
  </button>
);

const ImageGalleryDesktop = ({ imageUrls }) => {
  const thumbnails = [
    ...imageUrls.map((url, index) => ({
      src: url,
      alt: `Product thumbnail ${index + 1}`
    }))
  ];

  while (thumbnails.length < 4) {
    thumbnails.push({
      src: imageUrls[0], // תמונה ריקה
      alt: 'Placeholder image'
    });
  }

  const [currentImage, setCurrentImage] = useState(imageUrls[0] || '');
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);

  const handleThumbnailClick = (src) => {
    setCurrentImage(src); // עדכון מיידי של התמונה הראשית
  };

  useEffect(() => {
    if (imageUrls.length > 0) {
      setCurrentImage(imageUrls[0]); // עדכון התמונה הראשית כאשר imageUrls משתנה
    }
  }, [imageUrls]);

  useEffect(() => {
    const loadImages = async () => {
      const imagePromises = thumbnails.map((thumb) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = thumb.src;
          img.onload = resolve;
          img.onerror = resolve;
        });
      });

      await Promise.all(imagePromises);
      setAllImagesLoaded(true);
    };

    loadImages();
  }, [thumbnails]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    centerPadding: '0px',
    beforeChange: (_, newIndex) => {
      setCurrentImage(thumbnails[newIndex].src); // עדכון ישיר של התמונה הראשית
    },
  };

  return (
    <div className={styles.imageGallery}>
      <figure className={styles.mainImageWrapper}>
        <img
          src={currentImage}
          alt="Main product image"
          className={styles.mainImage}
          loading="lazy"
        />
      </figure>
      {allImagesLoaded ? (
        <div className={styles.thumbnailList}>
          <Slider {...settings} className={styles.thumbnailTrack}>
            {thumbnails.map((thumb, index) => (
              <img
                key={index}
                src={thumb.src}
                alt={thumb.alt}
                loading="lazy"
                className={styles.thumbnail}
                onClick={() => handleThumbnailClick(thumb.src)}
              />
            ))}
          </Slider>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default ImageGalleryDesktop;