import React from 'react';
import styles from './ProductInfo.module.css';
import WishlistButton from '../../WishlistButton/WishlistButton';

const ProductInfo = ({data}) => {
  // const ProductInfo = ({ title, description, category, designer, data }) => {
  // console.log(data.category)

  return (
    <article className={styles.productInfo}>
      <header className={styles.productHeader}>
      
      <div className={styles.hai}>
        <div>
        {/* <div className={styles.productPrice}>$16,000</div> */}
        <div className={styles.productPrice}></div>

        </div>
        <div>
            <h1 className={styles.productTitle}>
              {data.title}
              <span className={styles.productSubtitle}>{data.placement}, {data.designer}</span>
            </h1>
        </div>
      </div>


        <p className={styles.productDescription}>
          {data.description}
        </p>

        <WishlistButton product={data} />
      </header>

      <footer className={styles.productMeta}>
        <p className={styles.productCategory}>{data.designer}: {'מעצב'}</p>
        <p className={styles.productCategory}>{data.originalCategory} : {'קולקציה'}</p>
        <p className={styles.productCategory}>{'קטגוריה'} : {data.filterCategory}</p>
        <p className={styles.productCategory}>{data.placement} : {'מתאים ל'}</p>
      </footer>


    </article>
  );
};

export default ProductInfo;
