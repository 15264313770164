// import { useState, useEffect } from "react";
// import styles from './FilterBar.module.css';
// import FilterBarDesktop from "./FilterBarDesktop/FilterBarDesktop";
// import FilterBarMobile from "./FilterBarMobile/FilterBarMobile";

// export default function FilterBar() {
//   const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
//   const [displayText, setDisplayText] = useState("");

//   useEffect(() => {
//     // מזהה את הערך האחרון ב-URL
//     const urlSegments = window.location.pathname.split('/');
//     const lastSegment = urlSegments[urlSegments.length - 1] || "default";
//     console.log("lastSegment", lastSegment )

//     // טעינת קובץ JSON
//     fetch("/categories/ProductsCategories.json")
//       .then((response) => response.json())
//       .then((data) => {
//         // חיפוש הערך האחרון ב-JSON
//         const category = data.categories.find(cat => cat.name === lastSegment.toLowerCase());
//         // אם נמצא, להציג את השדה `text`. אם לא, להציג את הערך האחרון ב-URL
//         setDisplayText(category ? category.text : lastSegment);
//       })
//       .catch((error) => {
//         console.error("Error loading categories:", error);
//         // במקרה של שגיאה, הצגת הערך האחרון מה-URL
//         setDisplayText(lastSegment);
//       });

//     const handleResize = () => setIsMobile(window.innerWidth < 768);
//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   console.log("displayText", displayText);
//   return (
//     <header className={styles.header}>
//       {isMobile ? (
//         <FilterBarMobile  data={displayText}/>
//       ) : (
//         <FilterBarDesktop  data={displayText}/>
//       )}
//     </header>
//   );
// }


import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styles from "./FilterBar.module.css";
import FilterBarDesktop from "./FilterBarDesktop/FilterBarDesktop";
import FilterBarMobile from "./FilterBarMobile/FilterBarMobile";

export default function FilterBar() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [displayText, setDisplayText] = useState("");
  const location = useLocation(); // מזהה שינויים בנתיב ה-URL

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // מזהה את הערך האחרון ב-URL
    const urlSegments = location.pathname.split("/");
    const lastSegment = urlSegments[urlSegments.length - 1] || "default";

    // טעינת קובץ JSON
    fetch("/categories/ProductsCategories.json")
      .then((response) => response.json())
      .then((data) => {
        // חיפוש הערך האחרון ב-JSON
        console.log("lastSegment", lastSegment, data.categories)
        const category = data.categories.find(
          (cat) => cat.name === lastSegment
        );
        // אם נמצא, להציג את השדה `text`. אם לא, להציג את הערך האחרון ב-URL
        setDisplayText(category ? category.text : lastSegment);
      })
      .catch((error) => {
        console.error("Error loading categories:", error);
        // במקרה של שגיאה, הצגת הערך האחרון מה-URL
        setDisplayText(lastSegment);
      });
  }, [location]); // מאזין לשינויים בנתיב ה-URL

  return (
    <header className={styles.header}>
      {isMobile ? (
        <FilterBarMobile data={displayText} />
      ) : (
        <FilterBarDesktop data={displayText} />
      )}
    </header>
  );
}
