import React from 'react';
import styles from './HomaBrand.module.css';

const HomaBrand = () => {
  return (
    <section className={styles.section}>
      <img 
      draggable="false"
        // src="https://cdn.builder.io/api/v1/image/assets/TEMP/07433f6db61a9decd27b98f3acbe710e06015eaf7e60a4cfca0bc21ef24bcde2?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd" 
        src="./a.jpeg" 
        alt="Exclusive Home Brands Logo" 
        className={styles.logo} 
      />
      <h1 className={styles.title}>EXCLUSIVE HOME BRANDS</h1>
      <p className={styles.description}>
        משווקת ריהוט בית וגן מהמותגים המובילים באירופה
      </p>
    </section>
  );
};

export default HomaBrand;