// import React from 'react';
// import styles from './FooterColumn.module.css';

// const FooterColumn = ({ title, links }) => {
//   return (
//     <div className={styles.column}>
//       <h3 className={styles.title}>{title}</h3>
//       <ul className={styles.linkList}>
//         {links.map((link, index) => (
//           <li key={index} className={styles.linkItem}>{link}</li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default FooterColumn;

import React from 'react';
import styles from './FooterColumn.module.css'; // ודא שקובץ CSS קיים עם העיצוב שלך

const FooterColumn = ({ title, links }) => {
  return (
    <div className={styles.footerColumn}>
      <h3 className={styles.title}>{title}</h3>
      <ul className={styles.links}>
        {links.map((link, index) => (
          <li key={index} className={styles.linkItem}>
            <span
              onClick={link.action}
              className={styles.linkText}
              style={{ cursor: 'pointer' }}
            >
              {link.name}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FooterColumn;