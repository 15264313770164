// import React, { useEffect } from 'react';
// import styles from './QuotationPage.module.css';
// import HeroSection from '../HeroSection/HeroSection';
// import Offer from './Offer';
// import { useCart } from '../CartContext/CartContext';
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';


// const TableComponent = () => {
//   const { cartItems, removeFromCart } = useCart();

//   useEffect(() => {
//     console.log('מוצרים בעגלה:', cartItems);
//   }, [cartItems]);

//   const handleDelete = (name) => {
//     removeFromCart(name); // קריאה לפונקציה מהקונטקסט
//   };

//   const calculateTotal = () => {
//     return cartItems.reduce(
//       (total, item) => total + item.price * item.quantity,
//       0
//     );
//   };

//   const handleSavePDF = async () => {
//     const tableElement = document.getElementById('table-to-pdf'); // מזהה את הטבלה
//     const canvas = await html2canvas(tableElement, { scale: 2 }); // ממיר את הטבלה לתמונה
//     const imgData = canvas.toDataURL('image/png'); // יוצר תמונת PNG מהטבלה
//     const pdf = new jsPDF('p', 'mm', 'a4'); // יצירת מסמך PDF
//     const pdfWidth = pdf.internal.pageSize.getWidth();
//     const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

//     // הוספת הטבלה כקובץ תמונה ל-PDF
//     pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

//     // הוספת סה"כ להזמנה
//     const totalPrice = calculateTotal();
//     pdf.text(`${totalPrice.toFixed(2)}$`, 10, pdfHeight + 10);

//     // שמירת הקובץ
//     pdf.save('table_with_total.pdf');
//   };

//   console.log(cartItems[0])
//   return (
//     <div>
//       <HeroSection />
//       <div className={styles.container}>
//         <h2 className={styles.orderTitle}>ההזמנה שלי</h2>

//         <div className={styles.contentWrapper}>
//           <div className={styles.tableContainer}>
//             <table id="table-to-pdf" className={styles.table}>
//               <thead>
//                 <tr className={styles.tableHeaderRow}>
//                   <th className={styles.headerCell}></th>
//                   <th className={styles.headerCell}>שם המוצר</th>
//                   <th className={styles.headerCell}>כמות</th>
//                   <th className={styles.headerCell}>מחיר</th>
//                   <th className={styles.headerCell}>סה"כ</th>
//                   <th className={styles.headerCell}></th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {cartItems.length > 0 ? (
//                   cartItems.map((item) => (
//                     <tr key={item.name} className={styles.tableRow}>
//                       <td className={styles.cell}>
//                         <img
//                           src={item.image}
//                           alt={item.name}
//                           className={styles.productImage}
//                         />
//                       </td>
//                       <td className={styles.cell}>{item.name}</td>
//                       <td className={styles.cell}>{item.quantity}</td>
//                       {/* <td className={styles.cell}>{item.price}$</td>
//                       <td className={styles.cell}>
//                         {item.price * item.quantity}$
//                       </td> */}

//                       <td className={styles.cell}></td>
//                       <td className={styles.cell}>
//                       </td>

//                       <td className={styles.cell}>
//                         <button
//                           className={styles.deleteButton}
//                           onClick={() => handleDelete(item.name)}
//                         >
//                           <img
//                             src="https://cdn.builder.io/api/v1/image/assets/TEMP/f36b1d82f93f8738c58dcf60794ae8c989c9148e6ab209ada7cfe89371265d6f?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd"
//                             alt="Remove"
//                             className={styles.deleteIcon}
//                           />
//                         </button>
//                       </td>
//                     </tr>
//                   ))
//                 ) : (
//                   <tr>
//                     <td colSpan="6" className={styles.emptyMessage}>
//                       העגלה ריקה
//                     </td>
//                   </tr>
//                 )}
//               </tbody>
//             </table>
//           </div>
//           <Offer />
//         </div>
//         {cartItems.length > 0 && (
//             <div className={styles.totalPrice}>
//               {/* <h3>סה"כ להזמנה: {calculateTotal().toFixed(2)}$</h3> */}
//               <h3>: סה"כ להזמנה</h3>

//             </div>
//           )
//           }
//           {cartItems.length > 0 && (
//             <button onClick={handleSavePDF} className={styles.pdfButton}>
//             pdf-שמור כ          </button>
//           )
//           }




//       </div>
//     </div>
//   );
// };

// export default TableComponent;


import React, { useEffect } from 'react';
import styles from './QuotationPage.module.css';
import HeroSection from '../HeroSection/HeroSection';
import Offer from './Offer';
import { useCart } from '../CartContext/CartContext';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


const TableComponent = () => {
  const { cartItems, removeFromCart } = useCart();

  useEffect(() => {
    console.log('מוצרים בעגלה:', cartItems);
  }, [cartItems]);

  const handleDelete = (name) => {
    removeFromCart(name); // קריאה לפונקציה מהקונטקסט
  };

  const calculateTotal = () => {
    return cartItems.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
  };

  const handleSavePDF = async () => {
    const tableElement = document.getElementById('table-to-pdf'); // מזהה את הטבלה
    const canvas = await html2canvas(tableElement, { scale: 2 }); // ממיר את הטבלה לתמונה
    const imgData = canvas.toDataURL('image/png'); // יוצר תמונת PNG מהטבלה
    const pdf = new jsPDF('p', 'mm', 'a4'); // יצירת מסמך PDF
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    // הוספת הטבלה כקובץ תמונה ל-PDF
    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

    // הוספת סה"כ להזמנה
    // const totalPrice = calculateTotal();
    // pdf.text(`${totalPrice.toFixed(2)}$`, 10, pdfHeight + 10);

    // שמירת הקובץ
    pdf.save('table_with_total.pdf');
  };

  console.log(cartItems[0])
  return (
    <div>
      <HeroSection />
      <div className={styles.container}>
        <h2 className={styles.orderTitle}>ההזמנה שלי</h2>

        <div className={styles.contentWrapper}>
          <div className={styles.tableContainer}>
            <table id="table-to-pdf" className={styles.table}>
              <thead>
                <tr className={styles.tableHeaderRow}>
                  <th className={styles.headerCell}></th>
                  <th className={styles.headerCell}>שם המוצר</th>
                  <th className={styles.headerCell}>כמות</th>
                  {/* <th className={styles.headerCell}>מחיר</th> */}
                  {/* <th className={styles.headerCell}>סה"כ</th> */}
                  <th className={styles.headerCell}></th>
                </tr>
              </thead>
              <tbody>
                {cartItems.length > 0 ? (
                  cartItems.map((item) => (
                    <tr key={item.name} className={styles.tableRow}>
                      <td className={styles.cell}>
                        <img
                          src={item.image}
                          alt={item.name}
                          className={styles.productImage}
                        />
                      </td>
                      <td className={styles.cell}>{item.name}</td>
                      <td className={styles.cell}>{item.quantity}</td>


                      <td className={styles.cell}></td>
                      <td className={styles.cell}>
                      </td>

                      <td className={styles.cell}>
                        <button
                          className={styles.deleteButton}
                          onClick={() => handleDelete(item.name)}
                        >
                          <img
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/f36b1d82f93f8738c58dcf60794ae8c989c9148e6ab209ada7cfe89371265d6f?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd"
                            alt="Remove"
                            className={styles.deleteIcon}
                          />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className={styles.emptyMessage}>
                      העגלה ריקה
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <Offer />
        </div>
        {/* {cartItems.length > 0 && (
            <div className={styles.totalPrice}>
              <h3>: סה"כ להזמנה</h3>

            </div>
          )
          } */}
          {cartItems.length > 0 && (
            <button onClick={handleSavePDF} className={styles.pdfButton}>
            pdf-שמור כ          </button>
          )
          }




      </div>
    </div>
  );
};

export default TableComponent;