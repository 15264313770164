import React from 'react';
import styles from './FilterBarDesktop.module.css';

function FilterBarDesktop({data}) {
  console.log("data", data)
  return (
    <section className={styles.container}>
      <div className={styles.resultsInfo}>
        <div className={styles.leftSection}>
          <button className={styles.filterButton}>
            <img 
              draggable="false"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/946702cbdfdb6839ca8fd2b96c24b48e9ee7ee28a83d7266d75d1461bdc41013?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd" 
              alt="Filter" 
              className={styles.filterIcon} 
            />
            <span>Filter</span>
          </button>

          <img 
            draggable="false"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/f6f28ea9ed51c2f05a8f18be6aa0ce4e116f8bf6343ec87fd0fbcde9d8ece4d1?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd" 
            alt="Grid view" 
            className={styles.gridIcon} 
          />

          <img 
            draggable="false"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/900dcbdc62d6fefd7005032f2ad7b8904f46d7cba6b5181b0c1ba255fb2280ab?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd" 
            alt="List view" 
            className={styles.listIcon} 
          />

          <div className={styles.divider} aria-hidden="true" />
          <p className={styles.resultCount}>Showing 1–16 of 32 results</p>
        </div>

        <div className={styles.rightSection}>
          <h1 className={styles.collectionTitle}>{data}</h1>
        </div>
      </div>
    </section>
  );
}

export default FilterBarDesktop;