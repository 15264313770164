import * as React from "react";
import styles from './CategoryCircle.module.css';
import { useNavigate } from 'react-router-dom';

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};


export const CategoryCircle = ({ imageUrl, title, name, onClose }) => {
    const navigate = useNavigate();
  
    const handleClick = () => {
      navigate(`/${capitalizeFirstLetter(name)}`);
      if (onClose) onClose(); 
    };
  
  return (
    <div className={styles.categoryWrapper} onClick={handleClick}>
      <div className={styles.imageContainer}>
        <img
          loading="lazy"
          src={imageUrl}
          alt={title}
          className={styles.categoryImage}
        />
        <div className={styles.categoryTitle}>{title}</div>
      </div>
    </div>
  );
};

export default CategoryCircle;