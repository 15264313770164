// import React from 'react';
// import styles from './FooterDesktop.module.css';
// import SocialMediaLinks from './SocialMediaLinks';
// import FooterColumn from './FooterColumn';
// import Logo from './Logo';

// const FooterDesktop = () => {
//   const categoryLinks = [
//     'ריהוט לבית',
//     'מערכות ישיבה',
//     'פינות אוכל',
//     'ריהוט חצר וגינה',
//     'כסאות וכורסאות',
//     'שולחנות סלון'
//   ];

//   const mainLinks = [
//     'בית',
//     'צור קשר'
//   ];

//   return (
//     <footer className={styles.footer}>
//       <div className={styles.footerContent}>
//         <div className={styles.footerColumns}>
//           <SocialMediaLinks />
//           <FooterColumn title="קטגוריות" links={categoryLinks} />
//           <FooterColumn title="ראשי" links={mainLinks} />
//           <Logo />
//         </div>
//         <div className={styles.footerBottom}>
//           <span className={styles.termsOfService}>תקנון אתר</span>
//           <span className={styles.copyright}>© כל הזכויות שמורות להומה מותגי יוקרה</span>
//         </div>
//       </div>
//     </footer>
//   );
// };

// export default FooterDesktop;


import React from 'react';
import styles from './FooterDesktop.module.css';
import SocialMediaLinks from './SocialMediaLinks';
import FooterColumn from './FooterColumn';
import Logo from './Logo';
import { useNavigate } from 'react-router-dom';

const FooterDesktop = () => {
  const navigate = useNavigate();

  const mainLinks = [
    { name: 'בית', action: () => navigate('/') },
    { name: 'צור קשר', action: () => window.open('https://wa.me/message/RLCICLSB2DOOF1', '_blank') }
  ];


  const categories = [
    { id: 1, name: "Indoor_outdoor_sofas", text: 'ספות פנים וחוץ' },
    { id: 2, name: "Armchairs", text: 'כורסאות' },
    { id: 3, name: "Coffee_tables_indoor_outdoor", text: 'שולחנות קפה פנים וחוץ' },
    { id: 4, name: "Dining_tables_indoor_outdoor", text: 'שולחנות אוכל פנים וחוץ' },
    { id: 5, name: "Chairs_and_bar_stools", text: 'כיסאות וכיסאות בר' },
    { id: 6, name: "Indoor_outdoor_lighting", text: 'תאורה פנים וחוץ' },
    { id: 15, name: "Consoles_and_cabinets", text: 'קונסולות וארונות' },
  ];

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const categoryLinks = categories.map(category => ({
    name: category.text,
    action: () => navigate(`/${capitalizeFirstLetter(category.name)}`)
  }));
  
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.footerColumns}>
          <SocialMediaLinks />
          <FooterColumn title="קטגוריות" links={categoryLinks} />
          <FooterColumn title="ראשי" links={mainLinks} />
          <Logo />
        </div>
        <div className={styles.footerBottom}>
          <span className={styles.termsOfService}>תקנון אתר</span>
          <span className={styles.copyright}>© כל הזכויות שמורות להומה מותגי יוקרה</span>
        </div>
      </div>
    </footer>
  );
};

export default FooterDesktop;