import React from 'react';
import CategoryCard from './CategoryCard';
import styles from './CategorySelection.module.css';

const categories = [
  { id: 1, name: "Indoor_outdoor_sofas", text: 'ספות פנים וחוץ', image: "categories_images/Indoor_outdoor_sofas.png" },
  { id: 2, name: "Armchairs", text: 'כורסאות', image: "categories_images/Armchairs.png" },
  { id: 3, name: "Coffee_tables_indoor_outdoor", text: 'שולחנות קפה פנים וחוץ', image: "categories_images/Coffee_tables_indoor_outdoor.png" },
  { id: 4, name: "Dining_tables_indoor_outdoor", text: 'שולחנות אוכל פנים וחוץ', image: "categories_images/Dining_tables_indoor_outdoor.png" },
  { id: 5, name: "Chairs_and_bar_stools", text: 'כיסאות וכיסאות בר', image: "categories_images/Chairs_and_bar_stools.png" },
  { id: 6, name: "Indoor_outdoor_lighting", text: 'תאורה פנים וחוץ', image: "categories_images/Indoor_outdoor_lighting.png" },
  { id: 7, name: "Accessories_and_complementary_furniture_indoor_outdoor", text: 'אקססוריז וריהוט משלים', image: "categories_images/Accessories_and_complementary_furniture_indoor_outdoor.png" },
  { id: 8, name: "Carpets", text: 'שטיחים', image: "categories_images/Carpets.png" },
  { id: 9, name: "Shading_solutions", text: 'פתרונות הצללה', image: "categories_images/Shading_solutions.png" },
  { id: 10, name: "Outdoor_kitchens", text: 'מטבחי חוץ', image: "categories_images/Outdoor_kitchens.png" },
  { id: 11, name: "Sunbeds_and_loungers", text: 'מיטות שיזוף ומרבצים', image: "categories_images/Sunbeds_and_loungers.png" },
  { id: 12, name: "Ottomans_and_benches", text: 'הדומים וספסלים', image: "categories_images/Ottomans_and_benches.png" },
  { id: 13, name: "Beds_for_bedrooms", text: 'מיטות לחדרי שינה', image: "categories_images/Beds_for_bedrooms.png" },
  { id: 14, name: "Office_tables", text: 'שולחנות משרד', image: "categories_images/Office_tables.png" },
  { id: 15, name: "Consoles_and_cabinets", text: 'קונסולות וארונות', image: "categories_images/Consoles_and_cabinets.png" },
  { id: 16, name: "All_products", text: 'כל המוצרים', "image": "categories_images/All_products.png"  },
];


function CategoryGrid() {
  return (
    <main className={styles.categoryGrid}>
      {categories.map((category) => (
        <CategoryCard key={category.id} {...category} />
      ))}
    </main>
  );
}

export default CategoryGrid;

// import React, { useEffect, useState } from 'react';
// import CategoryCard from './CategoryCard';
// import styles from './CategorySelection.module.css';

// function CategoryGrid() {
//   const [categories, setCategories] = useState([]);

//   useEffect(() => {
//     async function fetchCategories() {
//       try {
//         const response = await fetch('/categories/products_categories.json'); // הנתיב לקובץ
//         if (!response.ok) {
//           throw new Error('Failed to fetch categories');
//         }
//         const data = await response.json();
//         setCategories(data); // שמירת הנתונים
//       } catch (error) {
//         console.error('Error loading categories:', error);
//       }
//     }

//     fetchCategories();
//   }, []);

//   if (!categories.length) {
//     return <p>Loading categories...</p>; // הודעת טעינה
//   }

//   return (
//     <main className={styles.categoryGrid}>
//       {categories.map((category) => (
//         <CategoryCard key={category.id} {...category} />
//       ))}
//     </main>
//   );
// }

// export default CategoryGrid;