import React from 'react';
import HeroSection from '../HeroSection/HeroSection';
import ProductSelection from './ProductSelection/ProductSelection';
import FilterBar from './FilterAndTitleBar/FilterBar/FilterBar';

function ProductsPage() {
  return (
    <main>
      <HeroSection />
      <FilterBar></FilterBar>
      <ProductSelection></ProductSelection>
    </main>
  );
}

export default ProductsPage;