// import React, { createContext, useContext, useState, useEffect } from 'react';

// const CartContext = createContext();

// export const CartProvider = ({ children }) => {
//   const [cartItems, setCartItems] = useState(() => {
//     const savedCart = localStorage.getItem('cartItems');
//     return savedCart ? JSON.parse(savedCart) : [];
//   });
//   const [idCounter, setIdCounter] = useState(() => {
//     const savedCounter = localStorage.getItem('idCounter');
//     return savedCounter ? parseInt(savedCounter, 10) : 1;
//   });

//   const addToCart = (item) => {
//     console.log(`item = ${item.image}`)
    
//     setCartItems((prevItems) => {
//       // בדוק אם המוצר כבר קיים בעגלה
//       const existingItem = prevItems.find((i) => i.name === item.name);

//       const updatedCart = existingItem
//         ? prevItems.map((i) =>
//             i.name === item.name
//               ? { ...i, quantity: i.quantity + 1 }
//               : i
//           )
//         : [
//             ...prevItems,
//             {
//               id: idCounter,
//               name: item.name,
//               price: item.price,
//               quantity: 1,
//               image: item.image,
//             },
//           ];

//       // עדכון ה-ID האוטומטי
//       if (!existingItem) {
//         setIdCounter((prevCounter) => prevCounter + 1);
//         localStorage.setItem('idCounter', idCounter + 1);
//       }

//       // שמירת העגלה ב-localStorage
//       localStorage.setItem('cartItems', JSON.stringify(updatedCart));
//       return updatedCart;
//     });
//   };

//   useEffect(() => {
//     localStorage.setItem('cartItems', JSON.stringify(cartItems));
//   }, [cartItems]);

//   return (
//     <CartContext.Provider value={{ cartItems, addToCart }}>
//       {children}
//     </CartContext.Provider>
//   );
// };

// export const useCart = () => useContext(CartContext);



import React, { createContext, useContext, useState, useEffect } from 'react';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(() => {
    const savedCart = localStorage.getItem('cartItems');
    return savedCart ? JSON.parse(savedCart) : [];
  });

  const addToCart = (item) => {
    setCartItems((prevItems) => {
      // חיפוש פריט בעגלה לפי שם
      const existingItem = prevItems.find((i) => i.name === item.name);
  
      if (existingItem) {
        // אם הפריט כבר קיים, עדכן את הכמות
        const updatedCart = prevItems.map((i) =>
          i.name === item.name
            ? { ...i, quantity: i.quantity + (item.quantity || 1) } // עדכון הכמות
            : i
        );
        localStorage.setItem('cartItems', JSON.stringify(updatedCart));
        return updatedCart;
      }
  
      // אם הפריט לא קיים, הוסף אותו עם כמות ברירת מחדל של 1
      const updatedCart = [...prevItems, { ...item, quantity: item.quantity || 1 }];
      localStorage.setItem('cartItems', JSON.stringify(updatedCart));
      return updatedCart;
    });
  };

  const removeFromCart = (name) => {
    setCartItems((prevItems) => {
      const updatedCart = prevItems.filter((item) => item.name !== name); // סינון לפי שם
      localStorage.setItem('cartItems', JSON.stringify(updatedCart)); // עדכון localStorage
      return updatedCart;
    });
  };

  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  return (
    <CartContext.Provider value={{ cartItems, addToCart, removeFromCart }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);