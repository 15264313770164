import React from 'react';
import styles from './Logo.module.css';

const Logo = () => {
  return (
    <div className={styles.logoContainer}>
      <img 
        // src="https://cdn.builder.io/api/v1/image/assets/TEMP/15cd6cbc0a56a4b64ad4328d0159689d9f00fad6d874e953abb43308daff8a25?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd" 
src="/homa_nav.png"
        alt="Company Logo" 
        className={styles.logo}
      />
      <p className={styles.description}>
      החברה מציעה את מגוון הפתרונות הייחודיים והספציפיים לכל סוגי הלקוחות והדרישות, לעסקים, ללקוחות פרטיים ולגופים ציבוריים
      </p>
    </div>
  );
};

export default Logo;