import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import styles from './BreadcrumbNav.module.css';

const BreadcrumbNav = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <nav aria-label="Breadcrumb" className={styles.breadcrumbContainer}>
      <ol className={styles.breadcrumb}>
        <li>
          <Link to="/" className={styles.link}>Home</Link>
        </li>
        {pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;

          return (
            <React.Fragment key={to}>
              <li className={styles.separator} aria-hidden="true">/</li>
              <li>
                {isLast ? (
                  <span className={styles.current} aria-current="page">
                    {decodeURIComponent(value)}
                  </span>
                ) : (
                  <Link to={to} className={styles.link}>
                    {decodeURIComponent(value)}
                  </Link>
                )}
              </li>
            </React.Fragment>
          );
        })}
      </ol>
    </nav>
  );
};

export default BreadcrumbNav;