// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import styles from "./MobileSideBar.module.css";

// const MobileSideBar = ({ onClose }) => {
//   const [categories, setCategories] = useState([]);
//   const [isCategoriesOpen, setCategoriesOpen] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const preventScroll = (e) => {
//       if (!e.target.closest(`.${styles.sidebar}`)) {
//         e.preventDefault(); // מניעת גלילה מחוץ ל-sidebar
//       }
//     };

//     // הוספת מאזיני גלילה
//     document.addEventListener("touchmove", preventScroll, { passive: false });
//     document.addEventListener("wheel", preventScroll, { passive: false });

//     // הוספת class ל-body למניעת גלילה בעמוד הראשי
//     document.body.classList.add("sidebar-active");

//     return () => {
//       // הסרת מאזיני גלילה
//       document.removeEventListener("touchmove", preventScroll);
//       document.removeEventListener("wheel", preventScroll);

//       // הסרת class מה-body
//       document.body.classList.remove("sidebar-active");
//     };
//   }, []);

//   useEffect(() => {
//     // טעינת הקטגוריות מקובץ JSON
//     fetch("/categories/ProductsCategories.json")
//       .then((response) => response.json())
//       .then((data) => setCategories(data.categories))
//       .catch((error) => console.error("Error fetching categories:", error));
//   }, []);

//   const toggleCategories = () => {
//     setCategoriesOpen(!isCategoriesOpen);
//   };

//   const handleCategoryClick = (categoryName) => {
//     navigate(`/${categoryName}`);
//     onClose();
//   };

//   const handleHomeClick = () => {
//     navigate("/");
//     onClose();
//   };

//   const handleCartClick = () => {
//     navigate("/Final");
//     onClose();
//   };

//   const handleContactClick = () => {
//     window.location.href = "tel:0521111111";
//     onClose();
//   };

//   return (
//     <div className={`${styles.sidebar} ${styles.sidebarOpen}`}>
//       <button className={styles.closeButton} onClick={onClose}>
//         ✖️
//       </button>
//       <ul className={styles.sidebarMenu}>
//         {/* מסך הבית */}
//         <li className={styles.menuItem} onClick={handleHomeClick}>
//           מסך הבית
//         </li>

//         {/* קטגוריות */}
//         <li onClick={toggleCategories} className={styles.menuItem}>
//           קטגוריות
//         </li>
//         {isCategoriesOpen && (
//           <ul className={styles.subMenu}>
//             {categories.map((category) => (
//               <li
//                 key={category.id}
//                 className={styles.subMenuItem}
//                 onClick={() => handleCategoryClick(category.name)}
//               >
//                 {category.text}
//               </li>
//             ))}
//           </ul>
//         )}

//         {/* עגלת הקניות */}
//         <li className={styles.menuItem} onClick={handleCartClick}>
//           עגלת הקניות
//         </li>

//         {/* צור קשר */}
//         <li className={styles.menuItem} onClick={handleContactClick}>
//           צור קשר
//         </li>
//       </ul>
//     </div>
//   );
// };

// export default MobileSideBar;





import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./MobileSideBar.module.css";
import CloseIcon from "@mui/icons-material/Close";

const categories = [
  { id: 1, name: "Indoor_outdoor_sofas", text: 'ספות פנים וחוץ', image: "categories_images/Indoor_outdoor_sofas.png" },
  { id: 2, name: "Armchairs", text: 'כורסאות', image: "categories_images/Armchairs.png" },
  { id: 3, name: "Coffee_tables_indoor_outdoor", text: 'שולחנות קפה פנים וחוץ', image: "categories_images/Coffee_tables_indoor_outdoor.png" },
  { id: 4, name: "Dining_tables_indoor_outdoor", text: 'שולחנות אוכל פנים וחוץ', image: "categories_images/Dining_tables_indoor_outdoor.png" },
  { id: 5, name: "Chairs_and_bar_stools", text: 'כיסאות וכיסאות בר', image: "categories_images/Chairs_and_bar_stools.png" },
  { id: 6, name: "Indoor_outdoor_lighting", text: 'תאורה פנים וחוץ', image: "categories_images/Indoor_outdoor_lighting.png" },
  { id: 7, name: "Accessories_and_complementary_furniture_indoor_outdoor", text: 'אקססוריז וריהוט משלים', image: "categories_images/Accessories_and_complementary_furniture_indoor_outdoor.png" },
  { id: 8, name: "Carpets", text: 'שטיחים', image: "categories_images/Carpets.png" },
  { id: 9, name: "Shading_solutions", text: 'פתרונות הצללה', image: "categories_images/Shading_solutions.png" },
  { id: 10, name: "Outdoor_kitchens", text: 'מטבחי חוץ', image: "categories_images/Outdoor_kitchens.png" },
  { id: 11, name: "Sunbeds_and_loungers", text: 'מיטות שיזוף ומרבצים', image: "categories_images/Sunbeds_and_loungers.png" },
  { id: 12, name: "Ottomans_and_benches", text: 'הדומים וספסלים', image: "categories_images/Ottomans_and_benches.png" },
  { id: 13, name: "Beds_for_bedrooms", text: 'מיטות לחדרי שינה', image: "categories_images/Beds_for_bedrooms.png" },
  { id: 14, name: "Office_tables", text: 'שולחנות משרד', image: "categories_images/Office_tables.png" },
  { id: 15, name: "Consoles_and_cabinets", text: 'קונסולות וארונות', image: "categories_images/Consoles_and_cabinets.png" },
  { id: 16, name: "All_products", text: 'כל המוצרים', "image": "categories_images/All_products.png"  },
];

const MobileSideBar = ({ onClose }) => {
  const [isCategoriesOpen, setCategoriesOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const preventScroll = (e) => {
      if (!e.target.closest(`.${styles.sidebar}`)) {
        e.preventDefault(); // מניעת גלילה מחוץ ל-sidebar
      }
    };

    // הוספת מאזיני גלילה
    document.addEventListener("touchmove", preventScroll, { passive: false });
    document.addEventListener("wheel", preventScroll, { passive: false });

    // הוספת class ל-body למניעת גלילה בעמוד הראשי
    document.body.classList.add("sidebar-active");

    return () => {
      // הסרת מאזיני גלילה
      document.removeEventListener("touchmove", preventScroll);
      document.removeEventListener("wheel", preventScroll);

      // הסרת class מה-body
      document.body.classList.remove("sidebar-active");
    };
  }, []);

  const toggleCategories = () => {
    setCategoriesOpen(!isCategoriesOpen);
  };

  const handleCategoryClick = (categoryName) => {
    navigate(`/${categoryName}`);
    onClose();
  };

  const handleHomeClick = () => {
    navigate("/");
    onClose();
  };

  const handleCartClick = () => {
    navigate("/Final");
    onClose();
  };

  const handleContactClick = () => {
    // window.location.href = "tel:0521111111";
    window.open('https://wa.me/message/RLCICLSB2DOOF1', '_blank')
    onClose();
  };

  return (
    <div className={`${styles.sidebar} ${styles.sidebarOpen}`}>
      <button className={styles.closeButton} onClick={onClose}>
        {/* ✖️ */}
        <CloseIcon />
      </button>
      <ul className={styles.sidebarMenu}>
        {/* מסך הבית */}
        <li className={styles.menuItem} onClick={handleHomeClick}>
          מסך הבית
        </li>

        {/* קטגוריות */}
        <li onClick={toggleCategories} className={styles.menuItem}>
          קטגוריות
        </li>
        {isCategoriesOpen && (
          <ul className={styles.subMenu}>
            {categories.map((category) => (
              <li
                key={category.id}
                className={styles.subMenuItem}
                onClick={() => handleCategoryClick(category.name)}
              >
                {category.text}
              </li>
            ))}
          </ul>
        )}

        {/* עגלת הקניות */}
        <li className={styles.menuItem} onClick={handleCartClick}>
          עגלת הקניות
        </li>

        {/* צור קשר */}
        <li className={styles.menuItem} onClick={handleContactClick}>
          צור קשר
        </li>
      </ul>
    </div>
  );
};

export default MobileSideBar;