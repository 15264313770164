// import React from 'react';
// import styles from './ImageGalleryMobile.module.css';

// const ScrollIndicator = ({ isActive }) => (
//   <div className={`${styles.scrollIndicator} ${isActive ? styles.activeIndicator : styles.inactiveIndicator}`} />
// );

// const ImageGalleryMobile = () => {
//   const totalIndicators = 13;
//   const activeIndex = 0;

//   return (
//     <section className={styles.carouselContainer}>
//       <div className={styles.imageWrapper}>
//         <img 
//           loading="lazy" 
//           src="https://cdn.builder.io/api/v1/image/assets/TEMP/f8e6170d1256fe45cc219d03efe5bdd1f3b1ecd562d77a3cd0f959abf3fefaa1?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd" 
//           className={styles.carouselImage} 
//           alt="Carousel main image"
//         />
//         <img 
//           loading="lazy" 
//           src="https://cdn.builder.io/api/v1/image/assets/TEMP/0457a7f2e2946117d1d18aa811d5665b6769eb4083eaf2977e11d6a979369145?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd" 
//           className={styles.iconImage} 
//           alt="Carousel icon"
//         />
//         <nav className={styles.scrollBar}>
//           {[...Array(totalIndicators)].map((_, index) => (
//             <ScrollIndicator key={index} isActive={index === activeIndex} />
//           ))}
//         </nav>
//       </div>
//     </section>
//   );
// };

// export default ImageGalleryMobile;


import React from 'react';
import Slider from 'react-slick';
import styles from './ImageGalleryMobile.module.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ScrollIndicator = ({ isActive }) => (
  <div className={`${styles.scrollIndicator} ${isActive ? styles.activeIndicator : styles.inactiveIndicator}`} />
);

const ImageGalleryMobile = () => {
  const images = [
    "https://cdn.builder.io/api/v1/image/assets/TEMP/f8e6170d1256fe45cc219d03efe5bdd1f3b1ecd562d77a3cd0f959abf3fefaa1?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
    "https://cdn.builder.io/api/v1/image/assets/TEMP/0457a7f2e2946117d1d18aa811d5665b6769eb4083eaf2977e11d6a979369145?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
    "https://cdn.builder.io/api/v1/image/assets/TEMP/2fe580d97bb01e536e4eaff3e935e5382b3cd8103e0cb20a2085507a20b5210f?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd"
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    afterChange: (index) => setActiveIndex(index),
  };

  const [activeIndex, setActiveIndex] = React.useState(0);

  return (
    <section className={styles.carouselContainer}>
      <Slider {...settings}>
        {images.map((src, index) => (
          <div key={index} className={styles.imageWrapper}>
            <img 
              loading="lazy" 
              src={src} 
              className={styles.carouselImage} 
              alt={`Carousel image ${index + 1}`}
            />
          </div>
        ))}
      </Slider>
      <nav className={styles.scrollBar}>
        {images.map((_, index) => (
          <ScrollIndicator key={index} isActive={index === activeIndex} />
        ))}
      </nav>
    </section>
  );
};

export default ImageGalleryMobile;