// import React from 'react';
// import styles from './FooterMobile.module.css';
// import SocialMediaLinks from './SocialMediaLinks';
// import FooterColumn from './FooterColumn';
// import Logo from './Logo';

// const FooterMobile = () => {
//   const categoryLinks = [
//     'ריהוט לבית',
//     'מערכות ישיבה',
//     'פינות אוכל',
//     'ריהוט חצר וגינה',
//     'כסאות וכורסאות',
//     'שולחנות סלון'
//   ];

//   const mainLinks = [
//     'בית',
//     'אודות',
//     'קטלוג מוצרים',
//     'צור קשר'
//   ];

//   return (
//     <footer className={styles.footer}>
//       <div className={styles.footerContent}>
//         <div className={styles.footerColumns}>
//           <SocialMediaLinks />
//           <FooterColumn title="קטגוריות" links={categoryLinks} />
//           <FooterColumn title="ראשי" links={mainLinks} />
//           <Logo />
//         </div>
//         <div className={styles.footerBottom}>
//           <span className={styles.termsOfService}>תקנון אתר</span>
//           <span className={styles.copyright}>© כל הזכויות שמורות להומה מותגי יוקרה</span>
//         </div>
//       </div>
//     </footer>
//   );
// };

// export default FooterMobile;
import React from "react";
import styles from './FooterMobile.module.css';
import ContentItem from './ContentItem';
import SocialIcon from './SocialIcon';

const contentItems = [
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/7eec1e1fafb6713bfe94c36e2fe45a8cf028a7bc52440520344950374f5016da?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd", text: "קטגוריות" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/f6278472007bd65f6aeaec0a23bd28fd8573826e8515bf2e33b741081204c95e?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd", text: "מותגים" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/364b304387bbe60f3792bf2c4387460c0ac7cc5760c9e85a409ecd8ed605610f?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd", text: "צור קשר" }
];

const socialIcons = [
  "https://cdn.builder.io/api/v1/image/assets/TEMP/fd4f680c08b035fed0824f77a28c1794d6b150c6a71c65a3e187225c3861ce68?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
  "https://cdn.builder.io/api/v1/image/assets/TEMP/9b7f045ccf06042e3e6ee9d9c9436ed559a9adb3aea567feac2980c8d598ec1f?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd",
  "https://cdn.builder.io/api/v1/image/assets/TEMP/33917e61b8c1ce048adafa565051a9a0e784ef302a9d950d4ef9cb9051236867?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd"
];

function FooterMobile() {
  return (
    <footer className={styles.footer}>
      <nav className={styles.content}>
        {contentItems.map((item, index) => (
          <ContentItem key={index} icon={item.icon} text={item.text} />
        ))}
      </nav>
      <section className={styles.socialSection}>
        <h2 className={styles.followUs}>FOLLOW US</h2>
        <div className={styles.socialIcons}>
          {socialIcons.map((icon, index) => (
            <SocialIcon key={index} src={icon} />
          ))}
        </div>
      </section>
      <div className={styles.copyright}>
        <p className={styles.copyrightText}>© כל הזכויות שמורות להומה מותגי יוקרה</p>
      </div>
    </footer>
  );
}

export default FooterMobile;