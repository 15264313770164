// import React, { useState, Suspense }from 'react';
// import styles from './NavbarMobile.module.css';
// import MenuLines from './MenuLines';
// import { useNavigate } from 'react-router-dom';
// import MobileSideBar from '../../MobileSideBar/MobileSideBar';

// const NavbarMobile = () => {
//   const navigate = useNavigate();
//   const [isSidebarVisible, setSidebarVisible] = useState(false);

//   const toggleSidebar = () => {
//     setSidebarVisible(!isSidebarVisible);
//   };

//   const socialIcons = [
//     { id: 1, src: 'https://cdn.builder.io/api/v1/image/assets/TEMP/28efc1255752066627044c29990f5fb4bddff21a996678c18bc82a907dee8867?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd', alt: 'Social media icon' },
//     { id: 2, src: 'https://cdn.builder.io/api/v1/image/assets/TEMP/24a8a5d5a60ec3bb7940e190744f3f78752362bc5e624e2e7c6fa8c4f2af5cee?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd', alt: 'Social media icon' }
//   ];


  
//   return (
//     <header className={styles.header}>
//       {/* <nav className={styles.socialLinks}>
//         {socialIcons.map(icon => (
//           <img
//             key={icon.id}
//             loading="lazy"
//             src={icon.src}
//             alt={icon.alt}
//             className={styles.socialIcon}
//             onClick={() => navigate('/Final')} // ניווט ל-URL של Final

//           />
//         ))}
//       </nav> */}


//       <nav className={styles.socialLinks}>
//         {socialIcons.map(icon => (
//           <img
//             key={icon.id}
//             loading="lazy"
//             src={icon.src}
//             alt={icon.alt}
//             className={styles.socialIcon}
//             onClick={() => {
//               if (icon.id === 1) {
//                 navigate('/Final'); 
//               } else if (icon.id === 2) {
//                 window.location.href = 'tel:0521212344'; 
//               }}}
//           />
//         ))}
//       </nav>





      
//       {/* <section className={styles.brandContainer}>
//         <div className={styles.titleWrapper}>
//         HOMA */}
//         {/* HOMA */}
//         {/* <img
//           src='/Assets/homa_logo.svg'
//           alt="Logo" 
//           className={styles.logo} 
//         /> */}
// {/* 
//         </div>
//       </section> */}
//       <section className={styles.brandContainer} onClick={() => navigate('/')}>
//         <div className={styles.titleWrapper}>HOMA</div>
//       </section>
      
//       <div className={styles.actionsWrapper}>
//         <button className={styles.actionButton} onClick={toggleSidebar}>
//           <img
//             loading="lazy"
//             src="https://cdn.builder.io/api/v1/image/assets/TEMP/5a15a321b6d7dcec0e9de9cc41af115a8f07d6ac1daa01e219186c9063b848dc?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd"
//             alt="Action button"
//             className={styles.actionIcon}
//           />
//         </button>
//       </div>


//       {isSidebarVisible && (
//         <Suspense fallback={<div>Loading...</div>}>
//           <MobileSideBar onClose={toggleSidebar} />
//         </Suspense>
//       )}

//     </header>
//   );
// };

// export default NavbarMobile;



// -----------------------------------------------------------------------------

// import React, { useState, useEffect, useRef, Suspense } from 'react';
// import styles from './NavbarMobile.module.css';
// import { useNavigate } from 'react-router-dom';
// import Autocomplete from '@mui/material/Autocomplete';
// import TextField from '@mui/material/TextField';
// import { options } from '../Options';
// import MobileSideBar from '../../MobileSideBar/MobileSideBar';
// import SearchIcon from '@mui/icons-material/Search';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import MenuIcon from '@mui/icons-material/Menu';

// const NavbarMobile = () => {
//   const navigate = useNavigate();
//   const [isSidebarVisible, setSidebarVisible] = useState(false);
//   const [showPopup, setShowPopup] = useState(false);
//   const [searchValue, setSearchValue] = useState('');
//   const popupRef = useRef(null);

//   const toggleSidebar = () => {
//     setSidebarVisible(!isSidebarVisible);
//   };



//   return (
//     <header className={styles.header}>
//       <nav className={styles.socialLinks}>
//         {/* <img
//           loading="lazy"
//           src="https://cdn.builder.io/api/v1/image/assets/TEMP/28efc1255752066627044c29990f5fb4bddff21a996678c18bc82a907dee8867?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd"
//           alt="Social media icon"
//           className={styles.socialIcon}
//           onClick={() => navigate('/Final')}
//         /> */}

//         <ShoppingCartIcon
//         alt="Social media icon"
//           className={styles.socialIcon}
//           onClick={() => navigate('/Final')}
//         ></ShoppingCartIcon>

//         <WhatsAppIcon
//             className={styles.socialIcon} 
//             onClick={() => window.open('https://wa.me/message/RLCICLSB2DOOF1', '_blank')}
//         ></WhatsAppIcon>


//       </nav>

//       <section className={styles.brandContainer} onClick={() => navigate('/')}>
//         <div className={styles.titleWrapper}>HOMA</div>
//       </section>

      
//       <SearchIcon
//         className={styles.socialIcon}
//         onClick={() => console.log("Search icon clicked!")}
//       />
//       <MenuIcon
//       alt="Menu button"
//       className={styles.actionIcon}

//       ></MenuIcon>

//       {/* <div className={styles.actionsWrapper}>
//         <button className={styles.actionButton} onClick={toggleSidebar}>
//           <img
//             loading="lazy"
//             src="https://cdn.builder.io/api/v1/image/assets/TEMP/5a15a321b6d7dcec0e9de9cc41af115a8f07d6ac1daa01e219186c9063b848dc?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd"
//             alt="Menu button"
//             className={styles.actionIcon}
//           />
//         </button> */}




//       {/* </div> */}


//       {/* {showPopup && (

//         <div className={styles.popup} ref={popupRef}>
//           <div className={styles.searchContainer}>
//             <Autocomplete
//               options={filteredOptions}
//               value={searchValue}
//               onInputChange={(event, newValue) => setSearchValue(newValue)}
//               renderInput={(params) => (
//                 <TextField {...params} label="חיפוש" variant="outlined" fullWidth />
//               )}
//               disablePortal
//               PaperComponent={() => null}
//               className={styles.autocomplete}
//             />
//           </div>

//           {searchValue && (
//             <div className={styles.popupBody}>
//               {filteredOptions.map((option, index) => (
//                 <div
//                   key={index}
//                   className={styles.row}
//                   onClick={() => handleProductClick(option)}
//                   style={{ cursor: 'pointer' }}
//                 >
//                   <img
//                     src={option.image_url}
//                     alt={option.name}
//                     className={styles.productImage}
//                   />
//                   <span className={styles.productName}>{option.name}</span>
//                 </div>
//               ))}
//             </div>
//           )}
//         </div>
//       )} */}

//       {isSidebarVisible && (
//         <Suspense fallback={<div>Loading...</div>}>
//           <MobileSideBar onClose={toggleSidebar} />
//         </Suspense>
//       )}
//     </header>
//   );
// };

// export default NavbarMobile;



import React, { useState, useEffect, useRef, Suspense } from 'react';
import styles from './NavbarMobile.module.css';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import MenuIcon from '@mui/icons-material/Menu';
import { options } from '../Options'; // מייבאים את ה-Options
import MobileSideBar from '../../MobileSideBar/MobileSideBar';


const NavbarMobile = () => {
  const navigate = useNavigate();
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const popupRef = useRef(null);

    const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  // סינון אפשרויות לפי ערך החיפוש
  const filteredOptions = Object.keys(options)
    .filter((key) =>
      key.toLowerCase().startsWith(searchValue.toLowerCase())
    )
    .slice(0, 5)
    .map((key) => ({ name: key, ...options[key] }));

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleProductClick = (option) => {
    const productUrl = `/${option.company_name}/${option.name
      .toLowerCase()
      .replace(/ /g, '-')}`;
    setShowPopup(false);
    navigate(productUrl);
  };

  return (
    <header className={styles.header}>
      <nav className={styles.socialLinks}>
        <ShoppingCartOutlinedIcon
          className={styles.socialIcon}
          onClick={() => navigate('/Final')}
        />
        <WhatsAppIcon
          className={styles.socialIcon}
          onClick={() =>
            window.open('https://wa.me/message/RLCICLSB2DOOF1', '_blank')
          }
        />
      </nav>

      <section className={styles.brandContainer} onClick={() => navigate('/')}>
        {/* <div className={styles.titleWrapper}>HOMA</div> */}
        <img src="/homa_nav.png" alt="Logo" className={styles.logo} />

      </section>
      
      <nav className={styles.socialLinks}>

      <SearchIcon
        className={styles.socialIcon}
        onClick={() => setShowPopup(true)}
      />
      <MenuIcon className={styles.socialIcon} onClick={toggleSidebar} />

      </nav>


      {showPopup && (
        <div className={styles.popup} ref={popupRef}>
          <div className={styles.searchContainer}>
            <Autocomplete
              options={filteredOptions} // שימוש באפשרויות המסוננות
              value={searchValue}
              onInputChange={(event, newValue) => setSearchValue(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="חיפוש" variant="outlined" fullWidth />
              )}
              disablePortal // מבטל את ה-dropdown המובנה
              PaperComponent={() => null} // מבטל את רכיב ה-Paper
              className={styles.autocomplete}
            />
          </div>
          <div className={styles.popupBody}>
            {searchValue &&
              filteredOptions.map((option, index) => (
                <div
                  key={index}
                  className={styles.row}
                  onClick={() => handleProductClick(option)} // ניווט לדף המוצר
                  style={{ cursor: 'pointer' }}
                >
                  <img
                    src={option.image_url}
                    alt={option.name}
                    className={styles.productImage}
                  />
                  <span className={styles.productName}>{option.name}</span>
                </div>
              ))}
          </div>
        </div>
      )}

      {isSidebarVisible && (
        <Suspense fallback={<div>Loading...</div>}>
          <MobileSideBar onClose={toggleSidebar} />

        </Suspense>
      )}
    </header>
  );
};

export default NavbarMobile;