import React from 'react';
import styles from './FilterBarMobile.module.css';

const FilterBarMobile = ({data}) => {
  const actionIcons = [
    { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/d1e5de39c503073bf6f73056699011d2026dbd880496e2eef18cb1f813f896d7?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd", alt: "Action 1" },
    { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/80d0442dd06d692049218715d0280e324b45e1c1c335212b55fe1acffd129a51?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd", alt: "Action 2" }
  ];

  return (
    <section className={styles.container}>
      <div className={styles.Title}>
        <h1 className={styles.collectionTitle}>{data}</h1>
      </div>
      <div className={styles.filterBar}>
        <div className={styles.filterOption}>
          <img 
            draggable="false"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/4c271d72d053e9a66e73ce86cfc47827e99d4a5a5c91d2aa8b91cd3390c57d58?placeholderIfAbsent=true&apiKey=61e6847707e64adabe6b05a1c0a8e8bd" 
            alt="Filter icon" 
            className={styles.filterIcon}
          />
          <span className={styles.filterText}>Filter</span>
        </div>
        <div className={styles.actionIcons}>
          {actionIcons.map((icon, index) => (
            <img
              draggable="false"
              key={index}
              src={icon.src}
              alt={icon.alt}
              className={styles.actionIcon}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FilterBarMobile;